import React, { useState } from "react";

import * as yup from "yup";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";

import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Typography } from "@mui/material";
import { useLanguageContext } from "../../app/context/LanguageContext";
import { rpeValues, sessionTypes } from "../../app/utils/enums";
import {
  deleteExerciseTrainingTemplate,
  postExerciseTrainingTemplate,
  postTraining,
  putTraining,
  selectPlan,
} from "../../app/reducers/plansSlice";
import CustomModal from "../../app/components/CustomModal";
import { mapExercises, unmapExercises } from "../../app/resources/simpleFunction";
import { useMainContext } from "../../app/context/MainContext";
import SaveTemplateForm from "./SaveTemplateForm";

interface Props {
  edit?: boolean;
  annual?: any;
  training?: any;
  closeModal?: any;
}

export const initialExerciseRow = {
  exercise_name: { id: null, name: "" },
};

export const initialExercises = {
  warm_up: [initialExerciseRow],
  main_part: [initialExerciseRow],
  cool_down: [initialExerciseRow],
};



const TrainingForm = (props: Props): React.ReactElement => {
  const { edit, annual, training, closeModal } = props;
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const { i18n } = useLanguageContext();
  const { currentProfile } = useMainContext();
  const annualPlans = useAppSelector(selectPlan);
  const [templateModal, setTemplateModal] = useState(false);
  const [trainingId, setTrainingId] = useState(training?.id ?? null);

  const exerciseTemplates =
    annualPlans?.exerciseTemplates?.map((i: any) => {
      return { value: i.id, name: i.template_name };
    }) ?? [];

  const fields = [
    {
      name: "day",
      label: i18n.plans.dayTime,
      type: "date",
      placeholder: i18n.plans.placDayTime,
      edit: edit ?? false,
    },
    {
      name: "time",
      label: i18n.plans.time,
      type: "time",
      placeholder: i18n.plans.placTime,
      edit: edit ?? false,
    },
    {
      name: "duration_in_minutes",
      label: i18n.plans.duration,
      type: "text",
    },
    {
      name: "session_type",
      label: i18n.plans.sessionType,
      type: "select",
      selectValues: sessionTypes,
      placeholder: i18n.plans.placSessionType,
      edit: edit ?? false,
    },
    {
      name: "session_description",
      label: i18n.plans.sessionDesc,
      type: "text",
      placeholder: i18n.plans.placSessionDesc,
      edit: edit ?? false,
    },
    {
      name: "rpe",
      label: i18n.plans.rpe,
      placeholder: i18n.plans.placRpe,
      type: "select",
      selectValues: rpeValues,
      edit: edit ?? false,
    },
    {
      name: "exercise_template",
      label: i18n.plans.rpe,
      placeholder: i18n.plans.placExercises,
      type: "selectAdd",
      selectValues: exerciseTemplates,
      exercisesOptions: annualPlans?.exercises,
      edit: false,
    },
    {
      name: "exercises",
      label: i18n.plans.exercises,
      placeholder: i18n.plans.placExercises,
      type: "ExerciseForm",
      edit: edit ?? false,
    },
  ];


  const init = {
    session_type: training?.session_type ?? "",
    session_description: training?.session_description ?? "",
    duration_in_minutes: training?.duration_in_minutes ?? "",
    exercise_template: "",
    day: training?.day_time
      ? new Date(training?.day_time)
      : new Date(annualPlans?.valuesWeek[0]?.day).getTime() >
        new Date().getTime()
      ? new Date(annualPlans?.valuesWeek[0]?.day)
      : new Date(),
    time: training?.day_time
      ? new Date(utcToZonedTime(training?.day_time, "Etc/UTC"))
      : new Date(),
    rpe: training?.rpe ?? "",
    exercises: training?.exercises
      ? unmapExercises(training?.exercises)
      : initialExercises,
  };

    const initialRowSchema = yup.object().shape({
      exercise_name: yup
        .object()
        .shape({
          id: yup.mixed().nullable(),
          name: yup.string().required(i18n.plans.requiredField),
        })
        .when(["duration", "sets", "reps", "load", "rest"], {
          is: (duration: any, sets: any, reps: any, load: any, rest: any) =>
            duration || sets || reps || load || rest,
          then: (schema) =>
            schema.shape({
              id: yup.mixed().nullable(),
              name: yup.string().required(i18n.plans.requiredField),
            }),
          otherwise: (schema) =>
            schema.shape({
              id: yup.mixed().nullable(),
              name: yup.string().optional(),
            }),
        }),
      duration: yup
        .number()
        .typeError(i18n.plans.mustNumber)
        .positive(i18n.plans.mustPositive)
        .nullable()
        .optional(),
      sets: yup
        .number()
        .typeError(i18n.plans.mustNumber)
        .positive(i18n.plans.mustPositive)
        .nullable()
        .optional(),
      reps: yup
        .number()
        .typeError(i18n.plans.mustNumber)
        .positive(i18n.plans.mustPositive)
        .nullable()
        .optional(),
      load: yup
        .number()
        .typeError(i18n.plans.mustNumber)
        .positive(i18n.plans.mustPositive)
        .nullable()
        .optional(),
      rest: yup
        .number()
        .typeError(i18n.plans.mustNumber)
        .positive(i18n.plans.mustPositive)
        .nullable()
        .optional(),
    });
    const partSchema = yup.array().of(initialRowSchema).ensure();

    const exerciseSchema = yup.object().shape({
      warm_up: partSchema,
      cool_down: partSchema,
      main_part: partSchema,
    });


  const scheme = yup.object({
    session_description: yup.string().required(i18n.plans.requiredField),
    session_type: yup.string().required(i18n.plans.requiredField),
    day: yup.string().required(i18n.plans.requiredField),
    time: yup.string().required(i18n.plans.requiredField),
    duration_in_minutes: yup.number().when([], (duration, schema) => {
      return schema
        .typeError(i18n.plans.mustNumber)
        .required(i18n.plans.requiredField)
        .positive(i18n.plans.mustPositive);
    }),
    exercises: exerciseSchema,
  });



  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);
      const date = format(values.day, "yyyy-MM-dd");
      const time = format(values.time, "HH:mm");

      const formData = {
        ...values,
        day_time: `${date as string}T${time as string}:00Z`,
        exercises: mapExercises(values.exercises),
        annual_plan: annual.id,
      };


      const editData = {
        session_type: values.session_type,
        session_description: values.session_description,
        duration_in_minutes: values.duration_in_minutes,
        day_time: `${date as string}T${time as string}:00Z`,
        exercises: mapExercises(values.exercises),
        annual_plan: annual.id,
        id: training?.id,
      };

      let res;


      if (!edit) res = await dispatch(postTraining(formData));

      if (edit) res = await dispatch(putTraining(editData));

      if (res?.meta.requestStatus === "fulfilled") {
        if(!edit && values.exercise_template === "") {
            setTrainingId(res.payload.id);
            setTemplateModal(true);
        }
        else {
          closeModal();
        }
      } else {
        setError(i18n.auth.errors.error);
      }

      setDisabled(false);
    } catch (error) {
      console.log("ERROR", error);
      setDisabled(false);
    }
  };


  const saveTemplate = async (values: any) => {
    await dispatch(
      postExerciseTrainingTemplate({
        ...values,
        training_id: trainingId,
        profile_id: currentProfile.id,
      })
    );
    closeModal();
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
          cancelFunction={closeModal}
        />
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
      {!edit && templateModal && (
        <CustomModal
          open={templateModal}
          onClose={() => {
            setTemplateModal(false);
            closeModal();
          }}
          text={"Do you want to save this exercies as a template?"}
          width={500}
        >
          <SaveTemplateForm
            closeModal={() => {
              setTemplateModal(false);
              closeModal();
            }}
            saveTemplate={saveTemplate}
          />
        </CustomModal>
      )}
    </>
  );
};
export default TrainingForm;
