import React, { useContext } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Button, Stack } from "@mui/material";
import { LanguageContext } from "../context/LanguageContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  overflow: "scroll",
  border: "none",
  boxShadow: "none",
  p: 4,
};

interface Props {
  open: boolean;
  onClose?: any;
  text?: string;
  onSubmit?: any;
  isInModal?: boolean;
}

const ConfirmModal = (props: Props): React.ReactElement => {
  const { open, onClose, text, onSubmit, isInModal = false } = props;
  const { i18n } = useContext(LanguageContext);
  return (
    <Modal open={open} onClose={onClose} disableAutoFocus={true}>
      <Box
        sx={{
          ...style,
          ...(isInModal && {
            p: 2,
            width: 500,
            height: 200,
            overflow: "hidden",
          }),
        }}
      >
        <Typography
          variant="h5"
          align="center"
          sx={{
            padding: isInModal ? "1rem" : "2rem",
            ...(isInModal ? { fontSize: "0.8rem" } : {}),
          }}
        >
          {text}
        </Typography>
        <Stack
          spacing={2}
          direction={"row"}
          display={"flex"}
          justifyContent={"center"}
          sx={{
            ...(isInModal && { marginTop: "4rem" }),
          }}
        >
          <Button onClick={onSubmit} variant="contained" color="success">
            {i18n.appData.yes}
          </Button>
          <Button onClick={onClose} variant="outlined" color="error">
            {i18n.appData.no}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
