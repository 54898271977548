import React, { useContext } from "react";
import { Role } from "../../utils/enums";
import CustomSelectDropdown from "../CustomSelectDropdown";
import { LanguageContext } from "../../context/LanguageContext";
import { sortAnnualPlans } from "../../resources/simpleFunction";

interface Props {
  annualPlans?: any;
  phases?: any;
  subphases?: any;
  selectedPhase?: any;
  selectedSubphase?: any;
  onSelectAnnualPlan?: any;
  onSelectPhase?: any;
  onSelectSubphase?: any;
  currentProfile?: any;
}

const SubphasePicker = (props: Props): React.ReactElement => {
  const {
    annualPlans,
    phases,
    subphases,
    selectedPhase,
    selectedSubphase,
    onSelectAnnualPlan,
    onSelectPhase,
    onSelectSubphase,
    currentProfile,
  } = props;

  const { i18n } = useContext(LanguageContext);

  return (
    <div style={{ ...styles.container, flexDirection: "row", flex: "1" }}>
      {/* Annual Plan Dropdown */}
      <CustomSelectDropdown
        disabled={currentProfile?.role === Role.Athlete}
        select={currentProfile?.role !== Role.Athlete}
        label={i18n.plans.seasonAthlete}
        value={annualPlans?.value?.id || ""}
        width={"30%"}
        selectValues={sortAnnualPlans(annualPlans.values)?.map((plan: any) => ({
          value: plan?.id,
          name: `${plan?.season?.name} - ${plan?.athlete?.name}`,
        }))}
        handleChange={(event: any) => onSelectAnnualPlan(event.target.value)}
      />

      {/* Phase Dropdown */}
      <CustomSelectDropdown
        disabled={!phases || phases.length === 0}
        select={true}
        label={i18n.appData.phase}
        value={selectedPhase || ""}
        width={"30%"}
        selectValues={phases?.map((phase: any) => ({
          value: phase?.id,
          name: phase?.name,
        }))}
        handleChange={(event: any) => onSelectPhase(event.target.value)}
      />

      {/* Subphase Dropdown */}
      <CustomSelectDropdown
        disabled={!subphases || subphases.length === 0}
        select={true}
        label={i18n.plans.subphase}
        value={selectedSubphase || ""}
        width={"30%"}
        selectValues={subphases?.map((subphase: any) => ({
          value: subphase?.id,
          name: subphase?.name,
        }))}
        handleChange={(event: any) => onSelectSubphase(event.target.value)}
      />
    </div>
  );
};

const styles = {
  container: {
    marginLeft: "2%",
    display: "flex",
    gap: "10px",
  },
};

export default React.memo(SubphasePicker);
