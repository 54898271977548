import React, { useContext } from "react";
import DefaultPalette from "../../theme/palette";
import {
  Box,
  Button,
  Checkbox,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext";
import { useNavigate } from "react-router-dom";
import {
  countTotalWeeks,
  getFormattedDate,
  getMicrocycleNumber,
  getRandomNumber,
} from "../../resources/simpleFunction";
import { Role } from "../../utils/enums";
import { useMainContext } from "../../context/MainContext";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch } from "../../hooks";
import {
  createPhases,
  getSubphaseData,
  updatePhase,
} from "../../reducers/plansSlice";
import CustomModal from "../CustomModal";
import PhaseForm from "../../../pages/forms/PhaseForm";
import SubphaseForm from "../../../pages/forms/SubphaseForm";
import CustomBlueButton from "../CustomBlueButton";

const getPhaseIndexes = (weeks: any[], phases: any[]) => {
  return phases.map((phase: any) => {
    const phaseStartIndex = weeks.findIndex(
      (week) => week.week_start_date.split("T")[0] === phase.start_date
    );
    const phaseEndIndex = weeks.findIndex(
      (week) => week.week_end_date.split("T")[0] === phase.end_date
    );
    const indexes = Array.from(
      { length: phaseEndIndex - phaseStartIndex + 1 },
      (_, i) => phaseStartIndex + i
    );
    return {
      ...phase,
      indexes: indexes,
    };
  });
};

interface Props {
  dataPhases: any;
  calendarData?: any[];
  onPhaseClick: (phase: any) => void;
  manipulatePhase: boolean;
  setManipulatePhase: (value: boolean) => void;
  annualPlanId: string;
}

const PhaseTable = (props: Props): React.ReactElement => {
  const {
    dataPhases,
    calendarData,
    onPhaseClick,
    manipulatePhase,
    setManipulatePhase,
    annualPlanId,
  } = props;
  const navigate = useNavigate();
  const { i18n } = useContext(LanguageContext);
  const { currentProfile } = useMainContext();
  const dispatch = useAppDispatch();
  const [phaseIndexes, setPhaseIndexes] = useState<any[]>([]);

  const spanSum = calendarData ? countTotalWeeks(calendarData) : 0;
  const weeks = calendarData?.flatMap((monthData) => monthData.weeks) ?? [];

  const palette = DefaultPalette("dark", "custom");

  const subphasesColors = [
    palette.custom.graph.orange,
    palette.custom.graph.yellow,
    palette.custom.graph.grey,
  ];

  const tableRef = useRef<HTMLTableElement>(null);
  const [tableWidth, setTableWidth] = useState<number>(0);

  const [selectedPhaseCells, setSelectedPhaseCells] = useState<number[]>([]);
  const [selectedSubphaseCells, setSelectedSubphaseCells] = useState<
    number[][]
  >([]);
  const [isPhaseModalOpen, setIsPhaseModalOpen] = useState(false);
  const [isSubphaseModalOpen, setIsSubphaseModalOpen] = useState(false);
  const [subphases, setSubphases] = useState<
    { name: string; cells: number[] }[]
  >([]);
  const [draggingPhase, setDraggingPhase] = useState(false);
  const [draggingSubphase, setDraggingSubphase] = useState(false);
  const [startPhaseIndex, setStartPhaseIndex] = useState<number | null>(null);
  const [endPhaseIndex, setEndPhaseIndex] = useState<number | null>(null);
  const [editModal, setEditModal] = useState(false);
  const [choosenPhase, setChoosenPhase] = useState<any>(null);
  const [choosenSubphase, setChoosenSubphase] = useState<any>(null);

  const handleTurnOffPhase = () => {
    setIsPhaseModalOpen(false);
    setIsSubphaseModalOpen(false);
    setSelectedPhaseCells([]);
    setSelectedSubphaseCells([]);
    // setManipulatePhase(false);
    setDraggingSubphase(false)
    setChoosenSubphase(null)
    setChoosenPhase(null)
    setEditModal(false)
  };

  useEffect(() => {
    if (tableRef.current) {
      setTableWidth(tableRef.current.offsetWidth);
    }
  }, [tableRef.current]);

  useEffect(() => {
    if (weeks.length > 0 && dataPhases.length > 0) {
      setPhaseIndexes(getPhaseIndexes(weeks, dataPhases));
    }
  }, [dataPhases]);

  const handleMousePhaseDown = (index: number) => {
    setSelectedPhaseCells([index]);
    setDraggingPhase(true);
    setStartPhaseIndex(index);
  };

  const handleMousePhaseEnter = (index: number) => {
    if (draggingPhase && startPhaseIndex !== null) {
      let newSelection: number[] = [];

      if (index > startPhaseIndex) {
        newSelection = Array.from(
          { length: index - startPhaseIndex + 1 },
          (_, i) => startPhaseIndex + i
        );
      } else {
        newSelection = Array.from(
          { length: startPhaseIndex - index + 1 },
          (_, i) => startPhaseIndex - i
        );
      }

      setSelectedPhaseCells(newSelection);
      setStartPhaseIndex(newSelection[0]);
      setEndPhaseIndex(newSelection[newSelection.length - 1]);
    }
  };

  const handleMouseUp = () => {
    setDraggingPhase(false);
    if (
      selectedPhaseCells.length > 0 &&
      !isSubphaseModalOpen &&
      selectedSubphaseCells?.length === 0
    ) {
      setIsPhaseModalOpen(true);
    }
  };

  const handlePhaseSubmit = async (v: any) => {
    const cells = selectedPhaseCells.sort((a, b) => a - b);
    const data = {
      name: v.phase_name,
      start_date: weeks[cells[0]].week_start_date?.split("T")[0],
      end_date: weeks[cells[cells.length - 1]].week_end_date?.split("T")[0],
      annual_plan: annualPlanId,
    };

    const response = await dispatch(createPhases(data));

    setIsPhaseModalOpen(false);
    setSelectedSubphaseCells([]);
  };

  const handleSubphaseDown = (index: number) => {
    console.log("Clicked Subphase Index:", index);
    console.log(
      "Available Phase Indexes:",
      phaseIndexes.map((p) => p.indexes)
    );

    // Ensure the index belongs to a phase
    const phase = phaseIndexes.find((p) => p.indexes.includes(index));

    if (!phase) {
      console.warn(`No matching phase found for index ${index}`);
      return; // Stop execution if index does not belong to any phase
    }
    if (phase) {
      setSelectedSubphaseCells((prev) => [...prev, [index]]);
      setDraggingSubphase(true);
      setSelectedPhaseCells(phase.indexes);
      setStartPhaseIndex(phase.indexes[0]);
      setEndPhaseIndex(phase.indexes[phase.indexes.length - 1]);
    }
  };

  const handleSubphaseEnter = (index: number) => {
    if (
      draggingSubphase &&
      startPhaseIndex !== null &&
      endPhaseIndex !== null
    ) {
      console.log("USLO", index);
      setSelectedSubphaseCells((prev) => {
        let lastSubphase = prev.length > 0 ? prev[prev.length - 1] : [];
        let newSelection: number[] = [];

        if (lastSubphase.length === 0) {
          newSelection = [index];
        } else {
          const start = Math.min(lastSubphase[0], index);
          const end = Math.max(lastSubphase[0], index);
          const boundedStart = Math.max(start, startPhaseIndex);
          const boundedEnd = Math.min(end, endPhaseIndex);

          newSelection = Array.from(
            { length: boundedEnd - boundedStart + 1 },
            (_, i) => boundedStart + i
          );
        }

        let updatedSubphases = [...prev];
        updatedSubphases[updatedSubphases.length - 1] = newSelection;
        return updatedSubphases;
      });
    }
  };

  const handleSubphaseUp = () => {
    setDraggingSubphase(false);
    setIsSubphaseModalOpen(true);
  };

  const handleSubphaseSubmit = async (v: any) => {
    const newSubphase = {
      name: v.subphase_name,
      cells: selectedSubphaseCells[selectedSubphaseCells.length - 1].sort(
        (a, b) => a - b
      ),
    };

    const sub = [...subphases, newSubphase];
    setSubphases(sub);

    const phase = phaseIndexes.find((phase) =>
      phase.indexes.some((index: any) => newSubphase.cells.includes(index))
    );

    if (phase) {
      const formattedData = {
        ...phase,
        id: phase.id,
        annual_plan: annualPlanId,
        subphases: [
          ...phase.subphases,
          {
            name: newSubphase.name,
            start_date:
              weeks[newSubphase.cells[0]].week_start_date?.split("T")[0],
            end_date:
              weeks[
                newSubphase.cells[newSubphase.cells.length - 1]
              ].week_end_date?.split("T")[0],
          },
        ],
      };

      const res = await dispatch(updatePhase(formattedData));
    }

    setIsSubphaseModalOpen(false);
    handleTurnOffPhase();
  };

  useEffect(() => {
    if (tableRef.current) {
      setTableWidth(tableRef.current.offsetWidth);
    }
  }, [tableRef.current]);

  const onRowPress = (microcycle: any) => {
    const mcNumber = calendarData
      ? getMicrocycleNumber(calendarData, microcycle?.start_date)
      : null;

    navigate("/weeklyPlan", {
      state: {
        weekStartDate: getFormattedDate(microcycle?.start_date),
        weekEndDate: getFormattedDate(microcycle?.end_date),
        mc: mcNumber,
        allWeeks: JSON.stringify(calendarData),
      },
    });
  };

  const headerPhases = [
    {
      text: i18n.plans.headerPhase.phase,
      height: "40px",
    },
    {
      text: i18n.plans.headerPhase.mesocycle,
      height: "40px",
    },
    {
      text: i18n.plans.headerPhase.microcycle,
      height: "40px",
    },
  ];

  const cellWidth: number =
    tableWidth > 0 && spanSum > 0 ? tableWidth / spanSum : 0;

  const styles: any = {
    headerCell: {
      border: `1px solid ${palette.custom.background}`,
      backgroundColor: palette.custom.lightGray,
      padding: "5px",
      minWidth: "106px",
      position: "relative",
    },
    text: {
      color: palette.custom.white,
      fontSize: "0.7rem",
      fontWeight: "normal",
      textAlign: "center",
      padding: "0px",
    },
    headerText: {
      color: palette.custom.white,
      fontWeight: "bold",
    },
    cellStyle: {
      height: "40px",
      width: `${cellWidth}px`,
      border: `0.3px solid ${palette.custom.lightGray}`,
    },
    noPhases: {
      color: palette.custom.white,
      fontWeight: "bold",
      textAlign: "center",
      padding: "10px",
      fontSize: "1rem",
    },
    table: {
      borderCollapse: "collapse",
      padding: "0px",
      width: "100%",
    },
    mainBox: {
      display: "flex",
      flexDirection: "row",
    },
    insideBox: {
      width: "100%",
    },
  };

  const handleNavigateSubphase = async (subphaseId: number) => {
    await dispatch(getSubphaseData(subphaseId?.toString()));
    navigate(`/subphase`);
  };

  const handleRightClick = (event: any, phase: any, id: any) => {
    event.preventDefault();
    setEditModal(true);
    setChoosenPhase(phase);
    setChoosenSubphase(id)
  };

  const handleEditSubphaseName = async (v: any) => {
     if (choosenPhase) {
       const formattedData = {
         ...choosenPhase,
         id: choosenPhase.id,
         annual_plan: annualPlanId,
          subphases: [
            ...choosenPhase.subphases.map((sub: any) => {
              if (sub.id === choosenSubphase) {
                return {
                  ...sub,
                  name: v.subphase_name,
                };
              }
              return sub;
            }),
          ],
       };

       const res = await dispatch(updatePhase(formattedData));
     }

     setIsSubphaseModalOpen(false);
     handleTurnOffPhase();
  }

function mapRows(weeks: any[], phases: any[], cellWidth: number) {
  const phaseRow: any[] = [];
  const subphaseRow: any[] = [];
  const microcycleRow: any[] = [];
  let skipToIndex = 0;

  for (let i = 0; i < weeks.length; i++) {
    if (i < skipToIndex) {
      continue;
    }
    const isSelectedPhase = selectedPhaseCells.includes(i);
    const validationPhase = manipulatePhase;

    const selectedSub = subphases.map((i) => i?.cells ?? []).flat();

    const validationSubphase =
      manipulatePhase
      // phaseIndexes.find((phase) => phase.indexes.includes(i)) &&
      // !selectedSub.includes(i);

      // console.log("Selected Subphases:", selectedSub);

    const week = weeks[i];
    const startDate = week.week_start_date.split("T")[0];
    const phase = phases.find((p: any) => p.start_date === startDate);
    const phaseStartIndex = i;

    if (phase) {
      const phaseEndIndex = weeks.findIndex(
        (w) => w.week_end_date.split("T")[0] === phase.end_date
      );
      const phaseColspan = phaseEndIndex - i + 1;

      phaseRow.push(
        <td
          key={`phase-${phase.id}-${getRandomNumber(1, 150)}`}
          colSpan={phaseColspan}
          style={{
            ...styles.cellStyle,
            width: `${cellWidth * phaseColspan}px`,
            cursor: currentProfile?.role === Role.Coach ? "pointer" : "default",
          }}
          onClick={() =>
            currentProfile?.role === Role.Coach ? onPhaseClick(phase) : {}
          }
        >
          <Typography
            noWrap
            sx={{
              ...styles.text,
              width: `${cellWidth * phaseColspan - 3}px`,
            }}
          >
            {phase.name}
          </Typography>
        </td>
      );

      if (phase.subphases && phase.subphases.length > 0) {
        let remainingColspan = phaseColspan;

        // Get all weeks within the phase
        const phaseWeeks = weeks.slice(phaseStartIndex, phaseEndIndex + 1);

        let lastFilledIndex = -1; // Track the last filled index to position subphases correctly

        phaseWeeks.forEach((week, weekIndex) => {
          // Find if a subphase starts in this specific week
          const subphase = phase.subphases.find(
            (sp: any) => sp.start_date === week.week_start_date.split("T")[0]
          );

          if (subphase) {
            const emptyCells = weekIndex - (lastFilledIndex + 1);

            // Fill empty cells only for the exact gap before the subphase
            for (let j = 0; j < emptyCells; j++) {
              const emptyIndex = phaseStartIndex + lastFilledIndex + 1 + j; // Ensure index is correct

              subphaseRow.push(
                <td
                  key={`empty-subphase-end-${phase.id}-${getRandomNumber(
                    650,
                    900
                  )}${j}`}
                  style={{
                    ...styles.cellStyle,
                    background: (() => {
                      const subphaseIndex = selectedSubphaseCells.findIndex(
                        (sub) => sub.includes(emptyIndex)
                      );
                      return subphaseIndex !== -1
                        ? subphasesColors[
                            subphaseIndex % subphasesColors.length
                          ]
                        : "transparent";
                    })(),
                    cursor: validationSubphase ? "pointer" : "default",
                  }}
                  onMouseDown={(event) => {
                    event.preventDefault();
                    if (validationSubphase) handleSubphaseDown(emptyIndex);
                  }}
                  onMouseEnter={(event) => {
                    event.preventDefault();
                    if (validationSubphase) handleSubphaseEnter(emptyIndex);
                  }}
                  onMouseUp={(event) => {
                    event.preventDefault();
                    if (validationSubphase) handleSubphaseUp();
                  }}
                >
                  <Typography noWrap sx={styles.text}></Typography>
                </td>
              );

              microcycleRow.push(
                <td
                  key={`empty-microcycle-${phase.id}-${getRandomNumber(
                    1,
                    150
                  )}${weekIndex}-${j}`}
                  style={styles.cellStyle}
                >
                  <Typography noWrap sx={styles.text}></Typography>
                </td>
              );
            }

            const subColspan = subphase.microcycles.length || 1;
            remainingColspan -= subColspan;

            subphaseRow.push(
              <td
                key={`subphase-${subphase.id}-${getRandomNumber(1, 150)}`}
                colSpan={subColspan}
                onClick={() => handleNavigateSubphase(subphase.id)}
                onContextMenu={(e) => {
                  e.preventDefault();
                  handleRightClick(e, phase, subphase.id);
                }}
                style={{
                  ...styles.cellStyle,
                  width: `${cellWidth * subColspan}px`,
                  cursor: "pointer",
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    ...styles.text,
                    width: `${cellWidth * subColspan - 3}px`,
                  }}
                >
                  {subphase.name}
                </Typography>
              </td>
            );

            // Add microcycles correctly under the subphase
            for (let mcIndex = 0; mcIndex < subColspan; mcIndex++) {
              microcycleRow.push(
                <td
                  key={`microcycle-${subphase.id}-${mcIndex}`}
                  style={{
                    ...styles.cellStyle,
                    width: `${cellWidth}px`,
                  }}
                  onClick={() => onRowPress(subphase.microcycles[mcIndex])}
                >
                  <Typography
                    noWrap
                    sx={{
                      ...styles.text,
                      cursor: "pointer",
                    }}
                  >
                    {mcIndex + 1}
                  </Typography>
                </td>
              );
            }

            lastFilledIndex = weekIndex + subColspan - 1; // Update last filled index
          }
        });

        // Fill remaining empty cells after all subphases
        const emptyAfterSubphases = phaseWeeks.length - (lastFilledIndex + 1);
        for (let k = 0; k < emptyAfterSubphases; k++) {
          const emptyIndex = phaseStartIndex + lastFilledIndex + 1 + k; // Ensure index is correct

          subphaseRow.push(
            <td
              key={`empty-subphase-end-${phase.id}-${getRandomNumber(
                150,
                200
              )}${k}`}
              style={{
                ...styles.cellStyle,
                background: (() => {
                  const subphaseIndex = selectedSubphaseCells.findIndex((sub) =>
                    sub.includes(emptyIndex)
                  );
                  return subphaseIndex !== -1
                    ? subphasesColors[subphaseIndex % subphasesColors.length]
                    : "transparent";
                })(),
                cursor: validationSubphase ? "pointer" : "default",
              }}
              onMouseDown={(event) => {
                event.preventDefault();
                if (validationSubphase) handleSubphaseDown(emptyIndex);
              }}
              onMouseEnter={(event) => {
                event.preventDefault();
                if (validationSubphase) handleSubphaseEnter(emptyIndex);
              }}
              onMouseUp={(event) => {
                event.preventDefault();
                if (validationSubphase) handleSubphaseUp();
              }}
            >
              <Typography noWrap sx={styles.text}></Typography>
            </td>
          );

          microcycleRow.push(
            <td
              key={`empty-microcycle-end-${phase.id}-${getRandomNumber(
                1,
                150
              )}${k}`}
              style={styles.cellStyle}
            >
              <Typography noWrap sx={styles.text}></Typography>
            </td>
          );
        }
      } else {
        for (let j = 0; j < phaseColspan; j++) {
          const emptyIndex = phaseStartIndex + j; // Ensure index is correct

          subphaseRow.push(
            <td
              key={`empty-subphase-${phase.id}-${getRandomNumber(
                150,
                200
              )}${j}`}
              style={{
                ...styles.cellStyle,
                background: (() => {
                  const subphaseIndex = selectedSubphaseCells.findIndex((sub) =>
                    sub.includes(emptyIndex)
                  );
                  return subphaseIndex !== -1
                    ? subphasesColors[subphaseIndex % subphasesColors.length]
                    : "transparent";
                })(),
                cursor: validationSubphase ? "pointer" : "default",
              }}
              onMouseDown={() =>
                validationSubphase && handleSubphaseDown(emptyIndex)
              }
              onMouseEnter={() =>
                validationSubphase && handleSubphaseEnter(emptyIndex)
              }
              onMouseUp={() => validationSubphase && handleSubphaseUp()}
            >
              <Typography noWrap sx={styles.text}></Typography>
            </td>
          );

          microcycleRow.push(
            <td
              key={`empty-microcycle-${phase.id}-${getRandomNumber(
                1,
                150
              )}${j}`}
              style={styles.cellStyle}
            >
              <Typography noWrap sx={styles.text}></Typography>
            </td>
          );
        }
      }

      skipToIndex = phaseEndIndex + 1;
      continue;
    }

    phaseRow.push(
      <td
        key={`empty-phase-${i}-${getRandomNumber(200, 300)}`}
        style={{
          ...styles.cellStyle,
          background: isSelectedPhase ? palette.custom.blue : "transparent",
          cursor: validationPhase ? "pointer" : "default",
        }}
        onMouseDown={() => validationPhase && handleMousePhaseDown(i)}
        onMouseEnter={() => validationPhase && handleMousePhaseEnter(i)}
      >
        <Typography noWrap sx={styles.text}></Typography>
      </td>
    );

    subphaseRow.push(
      <td
        key={`empty-subphase-${i}-${getRandomNumber(400, 500)}`}
        style={{
          ...styles.cellStyle,
          background: (() => {
            const subphaseIndex = selectedSubphaseCells.findIndex((sub) =>
              sub.includes(i)
            );
            return subphaseIndex !== -1
              ? subphasesColors[subphaseIndex % subphasesColors.length]
              : "transparent";
          })(),
          cursor: validationSubphase ? "pointer" : "default",
        }}
        onMouseDown={() => validationSubphase && handleSubphaseDown(i)}
        onMouseEnter={() => validationSubphase && handleSubphaseEnter(i)}
        onMouseUp={() => validationSubphase && handleSubphaseUp()}
      >
        <Typography noWrap sx={styles.text}></Typography>
      </td>
    );

    microcycleRow.push(
      <td
        key={`empty-microcycle-${i}-${getRandomNumber(600, 700)}`}
        style={styles.cellStyle}
      >
        <Typography noWrap sx={styles.text}></Typography>
      </td>
    );
  }

  return { phaseRow, subphaseRow, microcycleRow };
}

  const { phaseRow, subphaseRow, microcycleRow } = mapRows(
    weeks,
    dataPhases,
    cellWidth
  );

  return (
    <div style={styles.mainBox}>
      <table style={{ width: "max-content" }}>
        <tbody>
          {headerPhases.map((header: any, index: number) => (
            <tr key={index} style={{ height: header.height }}>
              <th style={styles.headerCell}>
                <Typography style={styles.headerText}>{header.text}</Typography>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
      <table ref={tableRef} style={{ width: "100%" }}>
        <tbody>
          <tr onMouseUp={() => manipulatePhase && handleMouseUp()}>
            {phaseRow}
          </tr>
          <tr>{subphaseRow}</tr>
          <tr>{microcycleRow}</tr>
        </tbody>
      </table>
      {isPhaseModalOpen && (
        <CustomModal open={isPhaseModalOpen} onClose={() => {}} text={""}>
          <PhaseForm
            edit={choosenPhase !== null && choosenPhase !== undefined}
            handlePhaseSubmit={handlePhaseSubmit}
            handleTurnOffPhase={handleTurnOffPhase}
          />
        </CustomModal>
      )}
      {isSubphaseModalOpen && (
        <CustomModal open={isSubphaseModalOpen} onClose={() => {}} text={""}>
          <SubphaseForm
          edit={false}
            handleSubphaseSubmit={handleSubphaseSubmit}
            handleTurnOffPhase={handleTurnOffPhase}
          />
        </CustomModal>
      )}
      {editModal && (
        <CustomModal open={editModal} onClose={() => {}} text={""}>
          <SubphaseForm
            edit={true}
            phase={choosenPhase}
            subphase={choosenSubphase}
            handleSubphaseSubmit={handleEditSubphaseName}
            handleTurnOffPhase={handleTurnOffPhase}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default PhaseTable;
