import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { unmapExercises } from "../../resources/simpleFunction";
import { useLanguageContext } from "../../context/LanguageContext";
import { ExercisePart } from "../../../pages/forms/ExerciseForm";
import { useAppSelector } from "../../hooks";
import { selectPlan } from "../../reducers/plansSlice";

const ExercisesListDisplay = ({ data }: any) => {
   const {i18n} = useLanguageContext();
  const [selectedTab, setSelectedTab] = useState(ExercisePart.WarmUp);
  const { exercises } = useAppSelector(selectPlan);

  const structuredExercises = unmapExercises(data); // Convert exercises into structured categories

  const handleTabChange = (_event: React.SyntheticEvent, newValue: ExercisePart) => {
    setSelectedTab(newValue);
  };

  return (
    <Box mt={3}>
      <Typography variant="caption" sx={{ color: "#fff", mb: 2 }}>
        {i18n.exercises.exercises}:
      </Typography>

      {/* Tabs for Warm-up, Cool-down, Main Part */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        textColor="inherit"
        indicatorColor="secondary"
        sx={{ mb: 2 }}
      >
        <Tab
          value={ExercisePart.WarmUp}
          label={i18n.exercises.warmUp}
          sx={{ color: "#fff" }}
        />
        <Tab
          value={ExercisePart.MainPart}
          label={i18n.exercises.mainPart}
          sx={{ color: "#fff" }}
        />

        <Tab
          value={ExercisePart.CoolDown}
          label={i18n.exercises.coolDown}
          sx={{ color: "#fff" }}
        />
      </Tabs>

      <TableContainer component={Paper} sx={{ backgroundColor: "#333" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "#fff" }}>
                {" "}
                {i18n.exercises.exercise}
              </TableCell>
              <TableCell sx={{ color: "#fff" }}>
                {i18n.exercises.duration}
              </TableCell>
              <TableCell sx={{ color: "#fff" }}>
                {i18n.exercises.sets}
              </TableCell>
              <TableCell sx={{ color: "#fff" }}>
                {i18n.exercises.reps}
              </TableCell>
              <TableCell sx={{ color: "#fff" }}>
                {i18n.exercises.load}
              </TableCell>
              <TableCell sx={{ color: "#fff" }}>
                {i18n.exercises.rest}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {structuredExercises[selectedTab].map(
              (exercise: any, index: number) => (
                <TableRow key={index}>
                  <TableCell sx={{ color: "#fff" }}>
                    {exercise.exercise_name?.name ?? "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    {exercise.duration ?? "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    {exercise.sets ?? "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    {exercise.reps ?? "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    {exercise.load ?? "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    {exercise.rest ?? "-"}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ExercisesListDisplay;
