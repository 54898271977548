import React, { useEffect, useState } from "react";
import NewRow from "../app/components/NewRow";
import SubphaseTable from "../app/components/annual/SubphaseTable";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  getAnnualPlan,
  getAnnualPlans,
  getSubphaseData,
  selectPlan,
} from "../app/reducers/plansSlice";
import { Box } from "@mui/material";
import EmptyComponent from "../app/components/EmptyComponent";
import { ListAlt } from "@mui/icons-material";
import { useLanguageContext } from "../app/context/LanguageContext";
import DefaultPalette from "../app/theme/palette";
import SubphasePicker from "../app/components/annual/SubphasePicker";
import { useMainContext } from "../app/context/MainContext";
import CircularIndeterminate from "../app/components/CircularIndeterminate";

const SubphasePage = (): React.ReactElement => {
  const plans = useAppSelector(selectPlan);
  const { i18n } = useLanguageContext();
  const { currentProfile } = useMainContext();
  const palette = DefaultPalette("dark", "custom");
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [phases, setPhases] = useState<any>([]);
  const [subphases, setSubphases] = useState<any>([]);
  const [selectedPhase, setSelectedPhase] = useState<any>(null);
  const [selectedSubphase, setSelectedSubphase] = useState<any>(null);

const onSelectAnnualPlan = async (selectedItem: any) => {
  try {
    setLoading(true);
    let res = await dispatch(getAnnualPlan(selectedItem));
    if (res.meta.requestStatus === "fulfilled") {
      setPhases(res.payload?.phases || []);
      setSubphases([]);

      if (plans.subphase?.id) {
        const foundPhase = res.payload?.phases.find((phase: any) =>
          phase.subphases.some((sub: any) => sub.id === plans.subphase.id)
        );

        if (foundPhase) {
          setSelectedPhase(foundPhase.id);
          setSubphases(foundPhase.subphases);
          setSelectedSubphase(plans.subphase.id);
        }
      }
    }
    setLoading(false);
  } catch (err: any) {
    setLoading(false);
  }
};

  const onSelectPhase = (selectedPhaseId: any) => {
    const selected = phases.find((phase: any) => phase.id === selectedPhaseId);
    setSelectedPhase(selectedPhaseId);
    setSubphases(selected?.subphases || []);
    setSelectedSubphase(null);
  };

  const onSelectSubphase = async (selectedItem: any) => {
    try {
      setLoading(true);
      let res = await dispatch(getSubphaseData(selectedItem));
      if (res.meta.requestStatus === "fulfilled") {
        setSelectedSubphase(selectedItem);
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isActive = true;

    const onScreenOpening = async (): Promise<void> => {
      try {
        setLoading(true);
        if (isActive) {
          await dispatch(getAnnualPlans());
          if (plans?.value?.id) {
            await onSelectAnnualPlan(plans?.value?.id);
          }
        }
      } catch (error: any) {
        console.error("Error in onScreenOpening:", error);
      } finally {
        setLoading(false);
      }
    };
    onScreenOpening();

    return () => {
      isActive = false;
    };
  }, [dispatch]);

  return (
    <div style={{ overflow: "hidden" }}>
      <NewRow height={"90px"} />
      <Box style={{ display: "flex" }}>
        <SubphasePicker
          annualPlans={plans}
          phases={phases}
          subphases={subphases}
          selectedPhase={selectedPhase}
          selectedSubphase={selectedSubphase}
          onSelectAnnualPlan={onSelectAnnualPlan}
          onSelectPhase={onSelectPhase}
          onSelectSubphase={onSelectSubphase}
          currentProfile={currentProfile}
        />
      </Box>
      <NewRow height={"90px"} />
      {!loading && (
        <>
          {Object.keys(plans.subphase)?.length > 0 && (
            <SubphaseTable subphase={plans.subphase} />
          )}
          {Object.keys(plans.subphase)?.length === 0 && (
            <EmptyComponent
              paddingTop="5rem"
              fontSize={"h4"}
              text={i18n.subphase.noSubphase}
              icon={
                <ListAlt
                  sx={{ fontSize: "3rem", color: palette.custom.gray }}
                />
              }
            />
          )}
        </>
      )}
      {loading && <CircularIndeterminate />}
    </div>
  );
};

export default SubphasePage;
