import React from "react";

import MenuItem from "@mui/material/MenuItem";
import CustomTextField from "./CustomTextField";
import DefaultPalette from "../theme/palette";
import { Box, Select, Typography } from "@mui/material";

interface Props {
  name: string;
  formik: any;
  label: string;
  selectValues: any;
  disabled?: boolean;
  placeholder?: string;
}

const CustomDropDownField = (props: Props): React.ReactElement => {
  const palette = DefaultPalette("dark", "custom");

  return (
    <Select
      sx={{
        color: palette.custom.lightGray,
        width: "100%",
        backgroundColor: palette.custom.background,
        input: { color: palette.custom.lightGray },
        outline: { color: palette.custom.background },
        borderColor: palette.custom.background,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.custom.lightGray,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.custom.lightGray,
        },
      }}
      id={props.name}
      placeholder={props.placeholder}
      name={props.name}
      variant="outlined"
      value={props.formik?.values[props.name]}
      onChange={props.formik.handleChange}
      error={
        props.formik.touched[props.name] &&
        Boolean(props.formik.errors[props.name])
      }
      displayEmpty
      renderValue={
        props.formik?.values[props.name] !== ""
          ? undefined
          : () => (
              <Typography
                sx={{ color: palette.custom.lightGray, opacity: 0.5 }}
              >
                {props.placeholder}
              </Typography>
            )
      }
    >
      {props.selectValues.map((item: any, index: any) => (
        <MenuItem
          key={index}
          value={item.value}
          sx={{ color: palette.custom.lightGray }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {item.icon && <img
              src={item.icon}
              alt={item.name}
              style={{ width: "auto", height: "50px" }}
            />}
            {item.name}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomDropDownField;
