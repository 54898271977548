import { rpeIcons } from "../resources/images";

export const importanceValue = [
  {
    name: "* Control",
    value: "PREPARATION",
  },
  {
    name: "** Performance",
    value: "PERFORMANCE",
  },
  {
    name: "*** Decisive",
    value: "DECISIVE",
  },
];

export const importanceDisplayObject: Record<string, string> = {
  PREPARATION: "*",
  PERFORMANCE: "**",
  DECISIVE: "***",
};

export const importanceDisplayCardObject: Record<string, string> = {
  PREPARATION: "* Control",
  PERFORMANCE: "** Performance",
  DECISIVE: "*** Decisive",
};

export enum Role {
  Coach = "COACH",
  Athlete = "ATHLETE",
  SportScienceAndMedicine = "SPORT_SCIENCE_AND_MEDICINE",
}

export enum WeekStatus {
  Past = "past",
  Current = "current",
  Future = "future",
}

export const sessionTypes = [
  {
    name: "Technical",
    value: "TECHNICAL",
  },
  {
    name: "Tactical",
    value: "TACTICAL",
  },
  {
    name: "Physical",
    value: "PHYSICAL",
  },
  {
    name: "Recovery",
    value: "RECOVERY",
  },
];

export const rpeValues = [
  {
    name: "1 - Watching TV and eating bon bons",
    value: "1",
    icon: rpeIcons.icon01,
  },
  {
    name: "2 - Comfortable, can maintain this pace all day",
    value: "2",
    icon: rpeIcons.icon02,
  },
  {
    name: "3 - Still comfortable, breathing a bit harder",
    value: "3",
    icon: rpeIcons.icon03,
  },
  {
    name: "4 - Sweating a little, feeling good, can talk easily",
    value: "4",
    icon: rpeIcons.icon04,
  },
  {
    name: "5 - Just above comfortable, sweating more, can talk easily",
    value: "5",
    icon: rpeIcons.icon05,
  },
  {
    name: "6 - Slightly breathless, definitely sweating",
    value: "6",
    icon: rpeIcons.icon06,
  },
  {
    name: "7 - Can still talk but don't really want to, sweating a lot",
    value: "7",
    icon: rpeIcons.icon07,
  },
  {
    name: "8 - Can only grunt, can maintain for a short time",
    value: "8",
    icon: rpeIcons.icon08,
  },
  {
    name: "9 - Very hard effort, probably going to die!",
    value: "9",
    icon: rpeIcons.icon09,
  },
  { name: "10 - Max effort, I am dead!!!", value: "10", icon: rpeIcons.icon10 },
];

export const rpe: any = {
  1: "Watching TV and eating bon bons",
  2: "Comfortable, can maintain this pace all day",
  3: "Still comfortable, breathing a bit harder",
  4: "Sweating a little, feeling good, can talk easily",
  5: "Just above comfortable, sweating more, can talk easily",
  6: "Slightly breathless, definitely sweating",
  7: "Can still talk but don't really want to, sweating a lot",
  8: "Can only grunt, can maintain for a short time",
  9: "Very hard effort, probably going to die!",
  10: "Max effort, I am dead!!!",
};

export const sessionObject: Record<string, string> = {
  TECHNICAL: "Technical",
  TACTICAL: "Tactical",
  PHYSICAL: "Physical",
  RECOVERY: "Recovery",
};
