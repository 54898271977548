export const playstore = {
  src: require("./images/playstore.svg").default,
};

export const appstore = {
  src: require("./images/appstore.svg").default,
};

export const rpeIcons = {
  icon01: require("./images/smajli_01.png"),
  icon02: require("./images/smajli_02.png"),
  icon03: require("./images/smajli_03.png"),
  icon04: require("./images/smajli_04.png"),
  icon05: require("./images/smajli_05.png"),
  icon06: require("./images/smajli_06.png"),
  icon07: require("./images/smajli_07.png"),
  icon08: require("./images/smajli_08.png"),
  icon09: require("./images/smajli_09.png"),
  icon10: require("./images/smajli_10.png"),
};
