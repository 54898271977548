import React, { useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Link,
  Typography,
} from "@mui/material";

import { Menu } from "@mui/icons-material";
import { LanguageContext } from "../context/LanguageContext";
import { authService } from "../services/authService";
import { useNavigate } from "react-router-dom";
import logo from "../resources/svg/logo.svg";


function DrawerComponent(): React.ReactElement {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const { i18n } = React.useContext(LanguageContext);
  const navigate = useNavigate();

  const onLogout = async () => {
    try {
      authService.logout();
      navigate("/");
      navigate(0);
    } catch (error) {}
  };

  const styles = {
    name: {
      padding: "0px",
      height: "0px",
      paddingLeft: "3px",
      lineHeight: "35px",
      display: "flex",
      alignItems: "center",
      alignSelf: "center",
      fontStyle: "normal",
    },
  }

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <List>
          {/* <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link href="/" underline="none" margin={1}>
                {i18n.appData.home}
              </Link>
            </ListItemText>
          </ListItem> */}
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link
                onClick={() => onLogout()}
                color="inhertit"
                underline="none"
                margin={1}
              >
                {i18n.appData.logout}
              </Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="a"
        href="/"
        maxWidth={"70px"}
        color={'white'}
        sx={{
          display: "flex",
          paddingLeft: "2%",
          cursor: "pointer",
          textDecoration: 'none',
        }}
      >
        <img src={logo} alt="Logo Icon" style={{ height: "70px" }} />

      </Box>
      <div style={{ display: "flex", alignContent: "flex-end" }}>
        <IconButton onClick={() => setOpenDrawer(!openDrawer)} sx={{color: 'white'}}>
          <Menu />
        </IconButton>
      </div>
    </>
  );
}
export default DrawerComponent;
