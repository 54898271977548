import React, { useContext, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  Button,
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
} from "@mui/material";
import CustomDateField from "../../app/components/CustomDateField";
import { LanguageContext } from "../../app/context/LanguageContext";
import CustomTextField from "../../app/components/CustomTextField";
import { useAppDispatch } from "../../app/hooks";
import {
  createPhases,
  deletePhase,
  updatePhase,
} from "../../app/reducers/plansSlice";
import { addWeeks, format, isAfter, isSunday } from "date-fns";
import {
  getDefaultPhaseEndDate,
  getDefaultSubPhaseEndDate,
  getNextMonday,
} from "../../app/resources/simpleFunction";
import DefaultPalette from "../../app/theme/palette";
import CustomBlueButton from "../../app/components/CustomBlueButton";
import CustomModal from "../../app/components/CustomModal";
import NewRow from "../../app/components/NewRow";
import * as yup from "yup";
import { Delete } from "@mui/icons-material";

interface PhaseSubPhaseFormProps {
  annualPlanId: number;
  season?: any;
  onClose?: () => void;
  phase: any;
  handleAddPhase?: () => void;
}

const PhaseSubPhaseForm: React.FC<PhaseSubPhaseFormProps> = (props) => {
  const { annualPlanId, season, onClose, phase, handleAddPhase } = props;
  const palette = DefaultPalette("dark", "custom");
  const startSeason = new Date(season.start_date);
  const endSeason = season.end_date;
  const { i18n } = useContext(LanguageContext);
  const dispatch = useAppDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [error, setError] = useState({ isError: false, errorText: "" });

  const initialValues = {
    name: phase?.name || "",
    // startDate: phase?.start_date ? new Date(phase.start_date) : getNextMonday(),
    // endDate: phase?.end_date
    //   ? new Date(phase.end_date)
    //   : getDefaultPhaseEndDate(),
    // hasSubPhases: phase?.subphases?.length > 0,
    subphases:
      phase?.subphases?.map((subphase: any) => ({
        id: subphase.id,
        name: subphase.name || "",
        startDate: subphase.start_date
          ? new Date(subphase.start_date)
          : getNextMonday(),
        endDate: subphase.end_date
          ? new Date(subphase.end_date)
          : getDefaultSubPhaseEndDate(new Date()),
        microcycles:
          subphase.microcycles?.map((microcycle: any) => ({
            id: microcycle.id,
            startDate: new Date(microcycle.start_date),
            endDate: new Date(microcycle.end_date),
          })) || [],
      })) || [],
  };

  const handleDelete = async () => {
    try {
      if (phase?.id) {
        await dispatch(deletePhase(phase.id));
        onClose && onClose();
      }
      setDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting phase:", error);
    }
  };

  const handleSubmit = async (values: any) => {
    setError({ isError: false, errorText: "" });
    try {
      const formattedData = {
        ...phase,
        name: values.name,
        // start_date: format(values.startDate, "yyyy-MM-dd"),
        // end_date: format(values.endDate, "yyyy-MM-dd"),
        subphases: values.subphases.map((subphase: any) => ({
          id: subphase.id,
          name: subphase.name,
          start_date: format(subphase.startDate, "yyyy-MM-dd"),
          end_date: format(subphase.endDate, "yyyy-MM-dd"),
          microcycles: subphase.microcycles.map((microcycle: any) => ({
            id: microcycle.id,
            start_date: format(microcycle.startDate, "yyyy-MM-dd"),
            end_date: format(microcycle.endDate, "yyyy-MM-dd"),
          })),
        })),
      };

      console.log("formattedData", formattedData);


      let response;
      if (phase?.id) {
        response = await dispatch(updatePhase(formattedData));
      } 

      if (response?.payload?.response?.data?.non_field_errors) {
        if (
          response?.payload?.response?.data?.non_field_errors[0].includes(
            "A Phase already exists"
          )
        ) {
          setError({
            isError: true,
            errorText: i18n.errors.modals.phaseSubphaseForm.phaseAlreadyExists,
          });
        }
      } else {
        onClose && onClose();
      }
    } catch (error) {
      console.error("Error submitting phase:", error);
    }
  };

  const scheme = yup.object().shape({
    name: yup.string().required(i18n.plans.requiredField),
    subphases: yup.array().of(
      yup.object().shape({
        name: yup.string().required(i18n.plans.requiredField),
      })
    ),
  });



 const styles = {
  text: {
    fontSize: 16,
    fontWeight: "normal",
    marginBottom: 5,
    marginTop: 5,
  }
 }
  return (
    <>
     {(phase !== null && phase !== undefined) && <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={scheme}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form noValidate>
              <Box
                sx={{
                  p: 4,
                  mb: 2,
                  borderRadius: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field name="name">
                      {({ field, form }: any) => (
                        <CustomTextField
                          {...field}
                          label={i18n.plans.phaseName}
                          formik={{ field, ...form }}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <Field name="endDate">
                      {({ field, form }: any) => (
                        <CustomDateField
                          {...field}
                          label={i18n.plans.phaseEndDate}
                          type="date"
                          formik={form}
                          changeArrow
                          minDate={
                            form.values.startDate
                              ? addWeeks(new Date(form.values.startDate), 1)
                              : null
                          }
                          maxDate={format(
                            getNextSunday(endSeason),
                            "yyyy-MM-dd"
                          )}
                          shouldDisableDate={(date: Date | null) => {
                            if (!date) return true;

                            const startDate = values.startDate
                              ? new Date(values.startDate)
                              : null;

                            if (!startDate) return true;

                            return !(
                              isSunday(date) &&
                              isAfter(date, addWeeks(startDate, 1))
                            );
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Field name="startDate">
                      {({ field, form }: any) => (
                        <CustomDateField
                          {...field}
                          label={i18n.plans.phaseStartDate}
                          type={"date"}
                          formik={form}
                          changeArrow={true}
                          minDate={season.start_date}
                          maxDate={format(
                            getNextSunday(endSeason),
                            "yyyy-MM-dd"
                          )}
                          shouldDisableDate={(date: Date | null) => {
                            if (!date) return true;
                            return date.getDay() !== 1;
                          }}
                        />
                      )}
                    </Field>
                  </Grid> */}
                </Grid>
                {/* <FormControlLabel
                  control={
                    <Field name="hasSubPhases">
                      {() => (
                        <Checkbox
                          checked={values.hasSubPhases}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            setFieldValue("hasSubPhases", checked);
                            if (checked && values.subphases.length === 0) {
                              const startDate = values.startDate;
                              const endDate = getDefaultSubPhaseEndDate(
                                new Date(startDate)
                              );
                              setFieldValue("subphases", [
                                {
                                  name: "",
                                  startDate: startDate,
                                  endDate: endDate,
                                },
                              ]);
                            } else if (!checked) {
                              setFieldValue("subphases", []);
                            }
                          }}
                        />
                      )}
                    </Field>
                  }
                  label={i18n.plans.hasMesocycles}
                /> */}
                <FieldArray name="subphases">
                  {({ push, remove }) => (
                    <>
                      {values.subphases.map(
                        (subPhase: any, subPhaseIndex: number) => {
                          const isFirstSubPhase = subPhaseIndex === 0;
                          // const previousSubPhase =
                          //   subPhaseIndex > 0
                          //     ? values.subphases[subPhaseIndex - 1]
                          //     : null;

                          // const minStartDate = isFirstSubPhase
                          //   ? new Date(values.startDate || startSeason)
                          //   : new Date(
                          //       previousSubPhase?.["endDate"] ||
                          //         values.startDate ||
                          //         startSeason
                          //     );

                          // if (!isFirstSubPhase && previousSubPhase) {
                          //   minStartDate.setDate(minStartDate.getDate() + 1);
                          // }

                          // const lastSubPhase =
                          //   values.subphases[values.subphases.length - 1];
                          // const lastSubPhaseStartDate = lastSubPhase?.endDate
                          //   ? new Date(lastSubPhase.endDate)
                          //   : null;

                          // const isAddDisabled =
                          //   lastSubPhaseStartDate &&
                          //   addWeeks(lastSubPhaseStartDate, 1) >
                          //     new Date(values.endDate);

                          return (
                            <Box
                              key={subPhaseIndex}
                              sx={{
                                p: 0,
                                paddingY: 2,
                                mt: 2,
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Field
                                    name={`subphases.${subPhaseIndex}.name`}
                                  >
                                    {({ field, form }: any) => (
                                      <CustomTextField
                                        {...field}
                                        fieldDetails={{
                                          parentField: "subphases",
                                          arrayIndex: subPhaseIndex,
                                          fieldKey: "name",
                                        }}
                                        label={`${i18n.plans.subphase} ${
                                          subPhaseIndex + 1
                                        } ${i18n.plans.name}`}
                                        formik={{ field, ...form }}
                                        fullWidth
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                {/* <Grid item xs={3}>
                                    <Field
                                      name={`subphases.${subPhaseIndex}.endDate`}
                                    >
                                      {({ field, form }: any) => {
                                        return (
                                          <CustomDateField
                                            {...field}
                                            label={i18n.plans.subphaseEndDate}
                                            type="date"
                                            formik={form}
                                            changeArrow
                                            minDate={
                                              values.subphases[subPhaseIndex]
                                                ?.startDate
                                                ? addWeeks(
                                                    new Date(
                                                      values.subphases[
                                                        subPhaseIndex
                                                      ].startDate
                                                    ),
                                                    1
                                                  )
                                                : null
                                            }
                                            maxDate={
                                              values.subphases[subPhaseIndex]
                                                ?.startDate
                                                ? (() => {
                                                    const subphaseMaxDate =
                                                      addWeeks(
                                                        new Date(
                                                          values.subphases[
                                                            subPhaseIndex
                                                          ].startDate
                                                        ),
                                                        7
                                                      );
                                                    const globalEndDate =
                                                      new Date(values.endDate);
                                                    return subphaseMaxDate >
                                                      globalEndDate
                                                      ? globalEndDate
                                                      : subphaseMaxDate;
                                                  })()
                                                : null
                                            }
                                            shouldDisableDate={(
                                              date: Date | null
                                            ) => {
                                              if (!date) return true;

                                              const startDate = values
                                                .subphases[subPhaseIndex]
                                                ?.startDate
                                                ? new Date(
                                                    values.subphases[
                                                      subPhaseIndex
                                                    ].startDate
                                                  )
                                                : null;

                                              const globalEndDate = new Date(
                                                values.endDate
                                              );

                                              if (!startDate) return true;

                                              const maxEndDate = addWeeks(
                                                startDate,
                                                7
                                              );

                                              const effectiveMaxDate =
                                                maxEndDate > globalEndDate
                                                  ? globalEndDate
                                                  : maxEndDate;

                                              return (
                                                !isSunday(date) ||
                                                !isAfter(
                                                  date,
                                                  addWeeks(startDate, 1)
                                                ) ||
                                                (isAfter(
                                                  date,
                                                  effectiveMaxDate
                                                ) &&
                                                  date.getTime() !==
                                                    globalEndDate.getTime())
                                              );
                                            }}
                                          />
                                        );
                                      }}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Field
                                      name={`subphases.${subPhaseIndex}.startDate`}
                                    >
                                      {({ field, form }: any) => (
                                        <CustomDateField
                                          {...field}
                                          label={i18n.plans.subphaseStartDate}
                                          type={"date"}
                                          formik={form}
                                          minDate={minStartDate}
                                          changeArrow
                                          maxDate={values.endDate}
                                          shouldDisableDate={(
                                            date: Date | null
                                          ) => {
                                            if (!date) return true;
                                            return date.getDay() !== 1;
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
 */}
                                {/* <Grid item xs={1}>
                                  <IconButton
                                    // variant="outlined"
                                    // color="secondary"
                                    onClick={() => {
                                      remove(values.subphases.length - 1);
                                      if (values.subphases.length === 1) {
                                        setFieldValue("hasSubPhases", false);
                                      }
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Grid> */}
                                {/** 
                                  <>
                                    {subPhaseIndex ===
                                      values.subphases.length - 1 && (
                                      <Grid item xs={4}>
                                        <Button
                                          variant="outlined"
                                          onClick={() => {
                                            // const nextStartDate = lastSubPhase
                                            //   ? new Date(lastSubPhase["endDate"])
                                            //   : new Date(
                                            //       values.startDate || startSeason
                                            //     );
                                            // if (lastSubPhase) {
                                            //   nextStartDate.setDate(
                                            //     nextStartDate.getDate() + 1
                                            //   );
                                            // }

                                            push({
                                              name: "",
                                              // startDate: nextStartDate,
                                              // endDate:
                                              //   getDefaultSubPhaseEndDate(
                                              //     nextStartDate
                                              //   ),
                                            });
                                          }}
                                          fullWidth
                                          sx={{
                                            "&:disabled": {
                                              cursor: "not-allowed",
                                              pointerEvents: "all !important",
                                            },
                                          }}
                                        >
                                          {i18n.appData.addSubPhase}
                                        </Button>
                                      </Grid>
                                    )}
                                  </>
                                  */}
                              </Grid>
                            </Box>
                          );
                        }
                      )}
                    </>
                  )}
                </FieldArray>
              </Box>
              {error.isError && (
                <Box>
                  <Typography color={"red"}>{error.errorText}</Typography>
                </Box>
              )}
              <Box mt={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  {i18n.appData.submit}
                </Button>
                {phase !== null && (
                  <CustomBlueButton
                    onPress={() => setDeleteModalOpen(true)}
                    label={i18n.plans.deletePhase}
                    textColor={palette.custom.red}
                    backgroundColor={palette.custom.white}
                  />
                )}
                <CustomBlueButton
                  onPress={onClose}
                  label={i18n.plans.cancel}
                  textColor={palette.custom.gray}
                  backgroundColor={"transparent"}
                  fontWeight={"normal"}
                  textDecoration={"underline"}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>}
      {(phase === null || phase === undefined) && (
        <React.Fragment>
          <Typography variant="h5" sx={styles.text}>
            {i18n.plans.phase.descCreatePhase}
          </Typography>
          <NewRow height={60} />
          <CustomBlueButton
            onPress={handleAddPhase}
            label={i18n.plans.phase.createPhase}
          />
          <CustomBlueButton
            onPress={onClose}
            label={i18n.plans.cancel}
            textColor={palette.custom.gray}
            backgroundColor={"transparent"}
            fontWeight={"normal"}
            textDecoration={"underline"}
          />
        </React.Fragment>
      )}
      {/* {phase !== null && (
        <React.Fragment>
          <Typography variant="h5" sx={styles.text}>
            {i18n.plans.phase.descEditPhase}
          </Typography>
          <NewRow height={60} />
          {phase !== null && (
            <CustomBlueButton
              onPress={() => setDeleteModalOpen(true)}
              label={i18n.plans.deletePhase}
              textColor={palette.custom.red}
              backgroundColor={palette.custom.white}
            />
          )}
          <CustomBlueButton
            onPress={onClose}
            label={i18n.plans.cancel}
            textColor={palette.custom.gray}
            backgroundColor={"transparent"}
            fontWeight={"normal"}
            textDecoration={"underline"}
          />
        </React.Fragment>
      )} */}
      {phase !== null && deleteModalOpen && (
        <CustomModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          text={i18n.plans.deletePhase}
          width={400}
        >
          <Box textAlign="center">
            <Typography
              style={{
                color: palette.custom.white,
                marginBottom: 20,
              }}
            >
              {i18n.plans.deletePhaseText}
            </Typography>
            <CustomBlueButton
              onPress={handleDelete}
              label={i18n.plans.delete}
              textColor={palette.custom.red}
              backgroundColor={palette.custom.white}
            />
            <NewRow height={10} />
            <CustomBlueButton
              onPress={() => setDeleteModalOpen(false)}
              label={i18n.plans.cancel}
              textColor={palette.custom.gray}
              backgroundColor={"transparent"}
              fontWeight={"normal"}
              textDecoration={"underline"}
            />
          </Box>
        </CustomModal>
      )}
    </>
  );
};

export default PhaseSubPhaseForm;
