import React, { useContext, useState, useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  Button,
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import {
  LanguageContext,
  useLanguageContext,
} from "../../app/context/LanguageContext";
import CustomTextField from "../../app/components/CustomTextField";
import * as yup from "yup";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
} from "@mui/material";
import CustomAutocompleteFreeSoloInput from "../../app/components/CustomAutocompleteFreeSoloInput";
import { useAppSelector } from "../../app/hooks";
import { selectPlan } from "../../app/reducers/plansSlice";

const initialRow = {
  exercise: { id: null, name: "" },
};

enum ExercisePart {
  WarmUp = "warm_up",
  CoolDown = "cool_down",
  MainPart = "main_part",
}

interface Props {
  closeModal?: any;
  edit?: boolean;
  editValues?: any;
  saveTemplate?: any;
}

const SaveTemplateForm = ({
  closeModal,
  saveTemplate,
  editValues,
}: Props): React.ReactElement => {
  const { i18n } = useLanguageContext();

  const validationSchema = yup.object().shape({
    template_name: yup.string().required(i18n.plans.requiredField),
  });

  const handleSubmit = async (values: any) => {
    saveTemplate(values);
    closeModal();
  };

  const initialValues = {
    template_name: "",
  };

  return (
    <>
      <Formik
        key={"template_name"}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, ...rest }) => {
          return (
            <Form noValidate style={{ width: "100%" }}>
              <Box mb={1} mt={9}>
                <CustomTextField
                  name="template_name"
                  formik={{ values, ...rest }}
                  label={i18n.exercises.templateName}
                />
              </Box>

              <Box mt={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginBottom: 20 }}
                >
                  {i18n.appData.submit}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SaveTemplateForm;
