import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { plansService } from "../services/plansService";
import { getFormatedWeekArray } from "../resources/simpleFunction";

export interface PlanState {
  values: any[];
  value: any;
  valueStats: any;
  valueCompetition: any;
  valuesCompetition: any[];
  valueWeek: any;
  valuesWeek: any[];
  valueSeason: any[];
  valueAthlete: any[];
  valueReportEvent: any[];
  valueReportTraining: any[];
  weekSessionValues: any;
  statusTemplate: any;
  statusExerciseTemplate: any;
  templates: any[];
  subphase: any;
  exercises: any[];
  exerciseTemplates: any[];
  exerciseTemplate: any;
  lastWeekSessionValues: any;
  loading: boolean;
  loadingCompetitions: boolean;
  loadingWeek: boolean;
  loadingSeason: boolean;
  loadingAthlete: boolean;
  error: string;
}

const initialState: PlanState = {
  values: [],
  value: {},
  valueStats: {},
  valueCompetition: {},
  valuesCompetition: [],
  valueWeek: [],
  valuesWeek: [],
  valueSeason: [],
  valueAthlete: [],
  valueReportEvent: [],
  valueReportTraining: [],
  weekSessionValues: [],
  statusTemplate: false,
  statusExerciseTemplate: false,
  templates: [],
  subphase: {},
  exercises: [],
  exerciseTemplates: [],
  exerciseTemplate: {},
  lastWeekSessionValues: [],
  loading: false,
  loadingCompetitions: false,
  loadingWeek: false,
  loadingSeason: false,
  loadingAthlete: false,
  error: "",
};

export const getAnnualPlans = createAsyncThunk(
  "annual_plans/get",
  async (): Promise<any> => {
    const response = await plansService.getAnnualPlans();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const getAnnualPlan = createAsyncThunk(
  "annual_plan/get",
  async (id: string): Promise<any> => {
    const response = await plansService.getAnnualPlan(id);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const getAnnualPlanStats = createAsyncThunk(
  "annual_plan_stats/get",
  async (id: string): Promise<any> => {
    const response = await plansService.getAnnualPlanStats(id);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const getCompetition = createAsyncThunk(
  "competition/get",
  async (id: string): Promise<any> => {
    const response = await plansService.getCompetition(id);
    return response.data;
  }
);

export const postCompetition = createAsyncThunk(
  "competition/post",
  async (payload: any, { dispatch }): Promise<any> => {
    const response = await plansService.postCompetition(payload);

    await(dispatch(getAnnualPlan(payload.annual_plan)));
    return response.data;
  }
);

export const putCompetition = createAsyncThunk(
  "competition/put",
  async (payload: any): Promise<any> => {
    const response = await plansService.putCompetition(payload, payload.id);
    return response;
  }
);

export const deleteCompetition = createAsyncThunk(
  "competition/delete",
  async (id: string): Promise<any> => {
    await plansService.deleteCompetition(id);
    return id;
  }
);

export const postCompetitionReport = createAsyncThunk(
  "competition_report/post",
  async (payload: any): Promise<any> => {
    const response = await plansService.postCompetitionReport(payload);
    return response.data;
  }
);

export const filterWeekPlan = createAsyncThunk(
  "week_plan/getFilter",
  async (payload: any): Promise<any> => {
    const response = await plansService.filterWeekPlan(payload);
    return {
      data: response.data,
      id: payload.id,
      startDayTime: payload.startDayTime,
      endDayTime: payload.endDayTime,
    };
  }
);

export const getWeekPlan = createAsyncThunk(
  "week_plan/get",
  async (id: any): Promise<any> => {
    const response = await plansService.getWeekPlan(id);
    return { data: response, id };
  }
);

export const getWeekSessionValues = createAsyncThunk(
  "session_values/get",
  async (payload: any): Promise<any> => {
    const response = await plansService.getSessionValues(payload);
    return response.data;
  }
);

export const getLastWeekSessionValues = createAsyncThunk(
  "last_week_session_values/get",
  async (payload: any): Promise<any> => {
    const response = await plansService.getSessionValues(payload);
    return {
      loads: response.data,
      name: payload.name,
      id: payload.id,
      start_week: payload.start_week,
      end_week: payload.end_week,
    };
  }
);

export const postTraining = createAsyncThunk(
  "training/post",
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await plansService.postTrainig(payload);

      dispatch(getExercises());

      return response.data;
    } catch (error) {}
  }
);

export const putTraining = createAsyncThunk(
  "training/put",
  async (payload: any, { dispatch }): Promise<any> => {
    try {
      const response = await plansService.putTraining(payload, payload.id);
      
      dispatch(getExercises());

      return response;
    } catch (error) {}
  }
);

export const deleteTraining = createAsyncThunk(
  "training/delete",
  async (id: string): Promise<any> => {
    await plansService.deleteTraining(id);
    return id;
  }
);

export const postTrainingReport = createAsyncThunk(
  "training_report/post",
  async (payload: any): Promise<any> => {
    const response = await plansService.postTrainingReport(payload);
    return response.data;
  }
);

export const getTrainingTemplates = createAsyncThunk(
  "training_template/get",
  async (): Promise<any> => {
    const response = await plansService.getTemplates();
    return response.data;
  }
);

export const postTrainingTemplate = createAsyncThunk(
  "training_template/post",
  async (payload: any): Promise<any> => {
    const response = await plansService.saveTemplate(payload);
    return response.data;
  }
);

export const deleteTrainingTemplate = createAsyncThunk(
  "training_template/delete",
  async (payload: any): Promise<any> => {
    const response = await plansService.deleteTemplate(payload);
    return response.data;
  }
);

export const applyTrainingTemplate = createAsyncThunk(
  "training_template/apply",
  async (payload: any): Promise<any> => {
    const response = await plansService.applyTemplate(payload);
    return response.data;
  }
);

export const getExercises = createAsyncThunk(
  "exercises/get",
  async (): Promise<any> => {
    const response = await plansService.getExercises();
    return response.data;
  }
);

export const getExerciseTrainingTemplates = createAsyncThunk(
  "exercise_training_templates/get",
  async (): Promise<any> => {
    const response = await plansService.getExerciseTemplates();
    return response.data;
  }
);

export const getExerciseTrainingTemplate = createAsyncThunk(
  "exercise_training_template/get",
  async (payload: any): Promise<any> => {
    const response = await plansService.getExerciseTemplate(payload);
    return response.data;
  }
);

export const postExerciseTrainingTemplate = createAsyncThunk(
  "exercise_training_template/post",
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await plansService.saveExerciseTemplate(payload);

      dispatch(getExerciseTrainingTemplates());

      return response.data;
    } catch (error) {}
  }
);

export const deleteExerciseTrainingTemplate = createAsyncThunk(
  "exercise_training_template/delete",
  async (payload: any): Promise<any> => {
    const response = await plansService.deleteExerciseTemplate(payload);
    return response.data;
  }
);

export const putExerciseTrainingTemplate = createAsyncThunk(
  "exercise_training_template/put",
  async (payload: any): Promise<any> => {
    const response = await plansService.putExerciseTemplate(payload);
    return response.data;
  }
);

export const applyExerciseTrainingTemplate = createAsyncThunk(
  "exercise_training_template/apply",
  async (payload: any): Promise<any> => {
    const response = await plansService.applyExerciseTemplate(payload);
    return response.data;
  }
);

export const getSubphaseData = createAsyncThunk(
  "subphase_statistics/get",
  async (payload: string): Promise<any> => {
    const response = await plansService.getSubphaseStatistics(payload);
    return response.data;
  }
);

export const setValueWeek = createAsyncThunk(
  "setValueWeek",
  async (payload: any): Promise<any> => {
    return payload;
  }
);

export const createPhases = createAsyncThunk(
  "phases",
  async (payload: any): Promise<any> => {
    const response = await plansService.createPhases(payload);
    return response;
  }
);

export const updatePhase = createAsyncThunk(
  "phases/update",
  async (payload: any): Promise<any> => {
    const response = await plansService.updatePhase(payload, payload.id);
    return response;
  }
);

export const deletePhase = createAsyncThunk(
  "phases/delete",
  async (id: string): Promise<any> => {
    await plansService.deletePhase(id);
    return id;
  }
);

export const plansSlice: any = createSlice({
  name: "plans_part",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<any>) => {
      state.value.push(action.payload);
    },
    resetPlansState: () => {
      return initialState;
    },
    resetValueState: (state) => {
      state.value = {
        phases: [],
      };
    },
    resetValuesWeekState: (state) => {
      state.valuesWeek = [];
    },
    resetValueStatsState: (state) => {
      state.valueStats = {};
    },
    resetValueAthleteState: (state) => {
      state.valueAthlete = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnnualPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnnualPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.values = action.payload;
      })
      .addCase(getAnnualPlans.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAnnualPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnnualPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.value = {
          ...action.payload,
          phases: [...action.payload.phases].sort((a: any, b: any) => {
            const dateA = new Date(a.start_date).getTime();
            const dateB = new Date(b.start_date).getTime();
            return dateA - dateB;
          }),
        };
        state.valuesCompetition = action.payload?.competitions;
        state.valueAthlete = action.payload?.athlete;
        state.valueSeason = action.payload?.season;
      })
      .addCase(getAnnualPlan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAnnualPlanStats.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnnualPlanStats.fulfilled, (state, action) => {
        state.loading = false;
        state.valueStats = action.payload;
      })
      .addCase(getAnnualPlanStats.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCompetition.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompetition.fulfilled, (state, action) => {
        state.loading = false;
        state.valueCompetition = action.payload;
      })
      .addCase(getCompetition.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postCompetition.pending, (state) => {
        state.loading = true;
      })
      .addCase(postCompetition.fulfilled, (state, action) => {
        state.loading = false;
        state?.valuesCompetition.push(action.payload);
        state.valuesWeek.forEach((weekDay: any) => {
          if (action.payload?.competitions.includes(weekDay?.day)) {
            weekDay?.objects.push(action?.payload);
            weekDay?.objects.sort((a: any, b: any) => {
              let dayTimeA = a.day_time ? a?.day_time : a?.date_of_competition;
              let dayTimeB = b.day_time ? b?.day_time : b?.date_of_competition;
              return dayTimeA.localeCompare(dayTimeB);
            });
          }
        });
      })
      .addCase(postCompetition.rejected, (state) => {
        state.loading = false;
      })
      .addCase(putCompetition.pending, (state) => {
        state.loading = true;
      })
      .addCase(putCompetition.fulfilled, (state, action) => {
        state.loading = false;
        /**
         * we have valuesWeek = [{day: 'some_day', objects: [objects of trainings and events]}]
         * if we change day of event we must replace that event from old_day to new_day
         *
         */
        state.valuesWeek = state.valuesWeek.map((weekDay: any) => {
          if (action.payload.data.date_of_competition?.includes(weekDay.day)) {
            let updatedObjects: any = [];
            const findedObject = weekDay.objects.find((obj: any) => {
              return obj.id === action.payload.data.id;
            });
            /**
             * finded object is undefined if it's not in day that already was in
             */
            if (findedObject) {
              updatedObjects = weekDay.objects.map((obj: any) => {
                if (obj.id === action.payload.data.id) {
                  return { ...state.valueWeek, ...action.payload.data };
                }
                return obj;
              });
            } else {
              const oldDay = state.valuesWeek.find((e) =>
                state.valueWeek.date_of_competition.includes(e.day)
              );
              updatedObjects = oldDay.objects.filter(
                (obj: any) => obj.id !== action.payload.data.id
              );

              /**
               * in next line we delete event from old day
               */
              state.valuesWeek = state.valuesWeek.map((e: any) => {
                if (state.valueWeek.date_of_competition.includes(e.day)) {
                  e.objects = updatedObjects;
                }
                return e.objects;
              });

              updatedObjects = [
                ...weekDay.objects,
                {
                  ...state.valueWeek,
                  ...action.payload.data,
                },
              ];
            }

            return {
              day: weekDay.day,
              objects: updatedObjects.sort((a: any, b: any) => {
                let dayTimeA = a.day_time ? a.day_time : a.date_of_competition;
                let dayTimeB = b.day_time ? b.day_time : b.date_of_competition;
                return dayTimeA.localeCompare(dayTimeB);
              }),
            };
          }
          return weekDay;
        });
        state.valueWeek = { ...state.valueWeek, ...action.payload.data };
      })
      .addCase(putCompetition.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteCompetition.pending, (state) => {
        state.loadingCompetitions = true;
      })
      .addCase(deleteCompetition.fulfilled, (state, action) => {
        state.loadingCompetitions = false;
        state.valuesCompetition = state.valuesCompetition.filter(
          (el: any) => el.id !== action.payload
        );
        state.valuesWeek = state.valuesWeek.map((weekDay: any) => {
          if (state.valueWeek.date_of_competition?.includes(weekDay.day)) {
            const updatedObjects = weekDay.objects.filter(
              (obj: any) => obj.id !== action.payload
            );

            return {
              ...weekDay,
              objects: updatedObjects,
            };
          }
          return weekDay;
        });

        state.valueWeek = {};
      })
      .addCase(deleteCompetition.rejected, (state) => {
        state.loadingCompetitions = false;
      })
      .addCase(postCompetitionReport.pending, (state) => {
        state.loadingCompetitions = true;
      })
      .addCase(postCompetitionReport.fulfilled, (state, action) => {
        state.loadingCompetitions = false;
        state.valuesWeek = state.valuesWeek.map((weekDay: any) => {
          for (let i = 0; i < weekDay.objects.length; i++) {
            if (
              weekDay?.objects[i]?.id === action.payload.competition &&
              weekDay.objects?.date_of_competition
            ) {
              weekDay?.objects[i]?.reports.push(action.payload);
            }
          }
          return weekDay;
        });
        state.valueWeek?.reports.push(action.payload);
      })
      .addCase(postCompetitionReport.rejected, (state) => {
        state.loadingCompetitions = false;
      })
      .addCase(getWeekPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWeekPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.valuesWeek = action.payload.data.filter(
          (e: any) => e.annual_plan === action.payload.id
        );
      })
      .addCase(getWeekPlan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getWeekSessionValues.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWeekSessionValues.fulfilled, (state, action) => {
        state.loading = false;
        state.weekSessionValues = action.payload;
      })
      .addCase(getWeekSessionValues.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getLastWeekSessionValues.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLastWeekSessionValues.fulfilled, (state, action) => {
        state.loading = false;
        state.lastWeekSessionValues = action.payload;
      })
      .addCase(getLastWeekSessionValues.rejected, (state) => {
        state.loading = false;
      })
      .addCase(filterWeekPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(filterWeekPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.valuesWeek = getFormatedWeekArray(
          action.payload?.data,
          action.payload?.startDayTime,
          action.payload?.endDayTime
        );
      })
      .addCase(filterWeekPlan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postTraining.pending, (state) => {
        state.loading = true;
      })
      .addCase(postTraining.fulfilled, (state, action) => {
        state.loading = false;
        state.valuesWeek.forEach((weekDay: any) => {
          if (action.payload?.day_time.includes(weekDay?.day)) {
            weekDay?.objects.push(action.payload);
            weekDay?.objects.sort((a: any, b: any) => {
              let dayTimeA = a.day_time ? a.day_time : a.date_of_competition;
              let dayTimeB = b.day_time ? b.day_time : b.date_of_competition;
              return dayTimeA.localeCompare(dayTimeB);
            });
          }
        });
      })
      .addCase(postTraining.rejected, (state) => {
        state.loading = false;
      })
      .addCase(putTraining.pending, (state) => {
        state.loading = true;
      })
      .addCase(putTraining.fulfilled, (state, action) => {
        state.loading = false;
        /**
         * we have valuesWeek = [{day: 'some_day', objects: [objects of trainings and events]}]
         * if we change day of training we must replace that training from old_day to new_day
         *
         */
        state.valuesWeek = state.valuesWeek.map((weekDay: any) => {
          if (action.payload.data.day_time?.includes(weekDay.day)) {
            let updatedObjects: any = [];
            const findedObject = weekDay.objects.find((obj: any) => {
              return obj.id === action.payload.data.id;
            });
            /**
             * finded object is undefined if it's not in day that already was in
             */
            if (findedObject) {
              updatedObjects = weekDay.objects.map((obj: any) => {
                if (obj.id === action.payload.data.id) {
                  return { ...state.valueWeek, ...action.payload.data };
                }
                return obj;
              });
            } else {
              const oldDay = state.valuesWeek.find((e) =>
                state.valueWeek.day_time.includes(e.day)
              );
              updatedObjects = oldDay.objects.filter(
                (obj: any) => obj.id !== action.payload.data.id
              );

              /**
               * in next line we delete training from old day
               */
              state.valuesWeek = state.valuesWeek.map((e: any) => {
                if (state.valueWeek.day_time.includes(e.day)) {
                  e.objects = updatedObjects;
                }
                return e.objects;
              });

              updatedObjects = [
                ...weekDay.objects,
                {
                  ...state.valueWeek,
                  ...action.payload.data,
                },
              ];
            }

            return {
              day: weekDay.day,
              objects: updatedObjects.sort((a: any, b: any) => {
                let dayTimeA = a.day_time ? a.day_time : a.date_of_competition;
                let dayTimeB = b.day_time ? b.day_time : b.date_of_competition;
                return dayTimeA.localeCompare(dayTimeB);
              }),
            };
          }
          return weekDay;
        });
        state.valueWeek = { ...state.valueWeek, ...action.payload.data };
      })
      .addCase(putTraining.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteTraining.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTraining.fulfilled, (state, action) => {
        state.loading = false;

        state.valuesWeek = state.valuesWeek.map((weekDay: any) => {
          if (state.valueWeek.day_time?.includes(weekDay.day)) {
            const updatedObjects = weekDay.objects.filter(
              (obj: any) => obj.id !== action.payload
            );

            return {
              ...weekDay,
              objects: updatedObjects,
            };
          }
          return weekDay;
        });

        state.valueWeek = {};
      })
      .addCase(deleteTraining.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postTrainingReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(postTrainingReport.fulfilled, (state, action) => {
        state.loading = false;
        state.valuesWeek = state.valuesWeek.map((weekDay: any) => {
          for (let i = 0; i < weekDay.objects.length; i++) {
            if (
              weekDay?.objects[i]?.id === action.payload.training &&
              weekDay.objects?.day_time
            ) {
              weekDay?.objects[i]?.report.push(action.payload);
            }
          }
          return weekDay;
        });
        state?.valueWeek?.report?.push(action?.payload);
      })
      .addCase(postTrainingReport.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTrainingTemplates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTrainingTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.templates = action.payload;
      })
      .addCase(getTrainingTemplates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postTrainingTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(postTrainingTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.statusTemplate = true;
        state.templates?.push(action.payload);
      })
      .addCase(postTrainingTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(applyTrainingTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(applyTrainingTemplate.fulfilled, (state, _action) => {
        state.loading = false;
        state.statusTemplate = true;
      })
      .addCase(applyTrainingTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteTrainingTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTrainingTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.templates = state.templates.filter(
          (el: any) => el.id !== action.meta.arg
        );
      })
      .addCase(deleteTrainingTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getExercises.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExercises.fulfilled, (state, action) => {
        state.loading = false;
        state.exercises = action.payload;
      })
      .addCase(getExercises.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getExerciseTrainingTemplates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExerciseTrainingTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.exerciseTemplates = action.payload;
      })
      .addCase(getExerciseTrainingTemplates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getExerciseTrainingTemplate.pending, (state) => {
        state.loading = true;
      })

      .addCase(getExerciseTrainingTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.exerciseTemplate = action.payload;
      })
      .addCase(getExerciseTrainingTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postExerciseTrainingTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(postExerciseTrainingTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.statusExerciseTemplate = true;
        state.exerciseTemplates?.push(action.payload);
      })
      .addCase(postExerciseTrainingTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(applyExerciseTrainingTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(applyExerciseTrainingTemplate.fulfilled, (state, _action) => {
        state.loading = false;
        state.statusExerciseTemplate = true;
      })
      .addCase(applyExerciseTrainingTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteExerciseTrainingTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteExerciseTrainingTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.exerciseTemplates = state.templates.filter(
          (el: any) => el.id !== action.meta.arg
        );
      })
      .addCase(deleteExerciseTrainingTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSubphaseData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubphaseData.fulfilled, (state, action) => {
        state.loading = false;
        state.subphase = action.payload;
      })
      .addCase(getSubphaseData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(setValueWeek.pending, (state) => {
        state.loading = true;
      })
      .addCase(setValueWeek.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.valueWeek = action.payload;
      })
      .addCase(setValueWeek.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createPhases.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPhases.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.response?.data.non_field_errors) {
          state.error = action.payload.response?.data.non_field_errors[0];
        } else {
          state.value = {
            ...state.value,
            phases: [...state.value.phases, action.payload.data].sort(
              (a: any, b: any) => {
                const dateA = new Date(a.start_date).getTime();
                const dateB = new Date(b.start_date).getTime();
                return dateA - dateB;
              }
            ),
          };
        }
      })
      .addCase(createPhases.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updatePhase.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePhase.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload.response?.data.non_field_errors) {
          state.error = action.payload.response?.data.non_field_errors[0];
        } else {
          const updatedPhase = action.payload.data;

          if (!updatedPhase?.id) {
            console.error("No phase ID found in payload.");
            return;
          }

          const updatedPhaseIndex = state.value.phases.findIndex(
            (phase: any) => phase.id === updatedPhase.id
          );

          if (updatedPhaseIndex !== -1) {
            // Update the phase in the array
            state.value.phases[updatedPhaseIndex] = {
              ...state.value.phases[updatedPhaseIndex],
              ...updatedPhase,
            };

            // Sort the phases by start_date
            state.value.phases.sort((a: any, b: any) => {
              const dateA = new Date(a.start_date).getTime();
              const dateB = new Date(b.start_date).getTime();
              return dateA - dateB;
            });
          } else {
            console.error(`Phase with ID ${updatedPhase.id} not found.`);
          }
        }
      })
      .addCase(updatePhase.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deletePhase.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePhase.fulfilled, (state, action) => {
        state.loading = false;
        state.value.phases = state.value.phases.filter(
          (phase: any) => phase.id !== action.payload
        );
      })
      .addCase(deletePhase.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectPlan = (state: RootState) => state.plans;

export const {
  resetPlansState,
  resetValueState,
  resetValuesWeekState,
  resetValueStatsState,
  resetValueAthleteState,
} = plansSlice.actions;
export default plansSlice.reducer;
