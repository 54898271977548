import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import DefaultPalette from "../theme/palette";

interface Props {
  formik?: any;
  name: string;
  fieldDetails?: {
    parentOne: string;
    parentField: string;
    arrayIndex: number;
    fieldKey: string;
  };
  label?: string;
  placeholder?: string;
  width?: string;
  options?: { id: string | number; name: string }[];
}

const CustomAutocompleteFreeSoloInput: React.FC<Props> = (props) => {
  const palette = DefaultPalette("dark", "custom");
  const fieldDetails = props.fieldDetails;

const fieldValue = fieldDetails
  ? props.formik?.values?.[fieldDetails.parentOne]?.[
      fieldDetails.parentField
    ]?.[fieldDetails.arrayIndex]?.[fieldDetails.fieldKey] || {
      id: null,
      name: "",
    }
  : props.formik?.values?.[props.name] || { id: null, name: "" };

const fieldError = fieldDetails
  ? props.formik?.errors?.[fieldDetails.parentOne]?.[
      fieldDetails.parentField
    ]?.[fieldDetails.arrayIndex]?.[fieldDetails.fieldKey]?.name || ""
  : props.formik?.errors?.[props.name]?.name || "";

const fieldTouched = fieldDetails
  ? props.formik?.touched?.[fieldDetails.parentOne]?.[
      fieldDetails.parentField
    ]?.[fieldDetails.arrayIndex]?.[fieldDetails.fieldKey]?.name || false
  : props.formik?.touched?.[props.name]?.name || false;


  return (
    <Autocomplete
      freeSolo
      options={props.options ?? []}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.name || ""
      }
      value={
        props.options?.find((opt) => opt.id === fieldValue.id) || fieldValue
      }
      onChange={(event, newValue) => {
        let newFieldValue = { id: null, name: "" };

        if (typeof newValue === "string") {
          newFieldValue = { id: null, name: newValue };
        } else if (newValue && typeof newValue === "object") {
          newFieldValue = { id: newValue.id, name: newValue.name };
        }

        if (fieldDetails) {
          props.formik.setFieldValue(
            `${fieldDetails.parentOne}.${fieldDetails.parentField}.${fieldDetails.arrayIndex}.${fieldDetails.fieldKey}`,
            newFieldValue
          );
        } else {
          props.formik.setFieldValue(props.name, newFieldValue);
        }
      }}
      onInputChange={(event, newValue, reason) => {
        if (reason === "input") {
          let newFieldValue = { id: null, name: newValue };

          if (fieldDetails) {
            props.formik.setFieldValue(
              `${fieldDetails.parentOne}.${fieldDetails.parentField}.${fieldDetails.arrayIndex}.${fieldDetails.fieldKey}`,
              newFieldValue
            );
          } else {
            props.formik.setFieldValue(props.name, newFieldValue);
          }
        }
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          backgroundColor: palette.custom.background,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={props.label}
          placeholder={props.placeholder}
          error={Boolean(fieldError && fieldError !== "")}
          helperText={fieldTouched && fieldError ? fieldError : ""}
          sx={{
            color: palette.custom.lightGray,
            width: "100%",
            // backgroundColor: palette.custom.background,
            input: { color: palette.custom.lightGray },
            outline: { color: palette.custom.background },
            borderColor: palette.custom.background,
            "& label.Mui-focused": {
              color: palette.custom.lightGray,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: palette.custom.lightGray,
                color: palette.custom.lightGray,
              },
              "&:hover fieldset": {
                borderColor: palette.custom.lightGray,
                color: palette.custom.lightGray,
              },
              "&.Mui-focused fieldset": {
                borderColor: palette.custom.lightGray,
                color: palette.custom.lightGray,
              },
            },
          }}
        />
      )}
    />
  );
};

export default CustomAutocompleteFreeSoloInput;
