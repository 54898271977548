import React, { useContext } from "react";
import { Formik, Form, Field } from "formik";
import { Button, Grid, Box } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";
import CustomTextField from "../../app/components/CustomTextField";

import DefaultPalette from "../../app/theme/palette";
import CustomBlueButton from "../../app/components/CustomBlueButton";
import * as yup from "yup";

interface PhaseFormProps {
  handleTurnOffPhase: () => void;
  handlePhaseSubmit: (v: "") => void;
  edit: boolean;
}

const PhaseForm: React.FC<PhaseFormProps> = (props) => {
  const { handlePhaseSubmit, handleTurnOffPhase, edit } = props;
  const palette = DefaultPalette("dark", "custom");
  const { i18n } = useContext(LanguageContext);

  const initialValues = {
    phase_name: "",
  };

  const handleSubmit = async (values: any) => {
    handlePhaseSubmit(values);
  };

  const scheme = yup.object().shape({
    phase_name: yup.string().required(i18n.plans.requiredField),
  });

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={scheme}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        return (
          <Form noValidate>
            <Box
              sx={{
                p: 4,
                mb: 2,
                borderRadius: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field name="phase_name">
                    {({ field }: any) => (
                      <CustomTextField
                        {...field}
                        label={i18n.plans.phaseName}
                        formik={formik}
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginBottom: 20 }}
              >
                {i18n.plans.phase.savePhaseAndContinue}
              </Button>
              <CustomBlueButton
                onPress={handleTurnOffPhase}
                label={i18n.plans.cancel}
                textColor={palette.custom.gray}
                backgroundColor={"transparent"}
                fontWeight={"normal"}
                textDecoration={"underline"}
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PhaseForm;
