import React, { useContext, useState, useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  Button,
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import {
  LanguageContext,
  useLanguageContext,
} from "../../app/context/LanguageContext";
import CustomTextField from "../../app/components/CustomTextField";
import * as yup from "yup";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
} from "@mui/material";
import CustomAutocompleteFreeSoloInput from "../../app/components/CustomAutocompleteFreeSoloInput";
import { useAppSelector } from "../../app/hooks";
import { selectPlan } from "../../app/reducers/plansSlice";
import { initialExerciseRow } from "./TrainingForm";

export enum ExercisePart {
  WarmUp = "warm_up",
  CoolDown = "cool_down",
  MainPart = "main_part",
}

interface Props {
  name?: any;
  label?: any;
  formik?: any;
}

const ExerciseForm = ({ name, label, formik }: Props): React.ReactElement => {
  const { i18n } = useLanguageContext();
  const [title, setTitle] = React.useState(ExercisePart.WarmUp);
  const { exercises } = useAppSelector(selectPlan);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: ExercisePart
  ) => {
    setTitle(newAlignment);
  };

  const styles: any = {
    cell: { textAlign: "center", color: "#fff" },
  };

  return (
    <FieldArray
      name={`${name}.${title}`}
      render={(arrayHelpers) => (
        <>
          <Box sx={{ p: 2, mb: 2, borderRadius: 2, color: "fff" }}>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              style={{
                padding: "10px",
                borderRadius: "5px",
                color: "#fff",
                fontSize: "1.2rem",
              }}
            >
              {i18n.exercises.exercises}
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={title}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              sx={{
                margin: "0 auto",
                width: "100%",
                justifyContent: "center",
                color: "#fff",
              }}
            >
              <ToggleButton value={ExercisePart.WarmUp}>
                {i18n.exercises.warmUp}
              </ToggleButton>
              <ToggleButton value={ExercisePart.MainPart}>
                {i18n.exercises.mainPart}
              </ToggleButton>
              <ToggleButton value={ExercisePart.CoolDown}>
                {i18n.exercises.coolDown}
              </ToggleButton>
            </ToggleButtonGroup>

            <TableContainer
              component={Paper}
              style={{
                maxWidth: "100%",
                margin: "20px auto",
                padding: "20px",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      borderBottom: "blue solid 2px",
                      color: "#fff",
                    }}
                  >
                    <TableCell
                      style={{
                        width: "35%",
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      {i18n.exercises.exercise}
                    </TableCell>
                    <TableCell style={styles.cell}>
                      {i18n.exercises.duration}
                    </TableCell>
                    <TableCell style={styles.cell}>
                      {i18n.exercises.sets}
                    </TableCell>
                    <TableCell style={styles.cell}>
                      {i18n.exercises.reps}
                    </TableCell>
                    <TableCell style={styles.cell}>
                      {i18n.exercises.load}
                    </TableCell>
                    <TableCell style={styles.cell}>
                      {i18n.exercises.rest}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formik.values?.[name]?.[title]?.map(
                    (ex: any, index: number) => (
                      <TableRow key={index} sx={{ color: "#fff" }}>
                        <TableCell style={{ width: "35%" }}>
                          <CustomAutocompleteFreeSoloInput
                            name={`${name}.${title}.${index}.exercise_name`}
                            formik={formik}
                            options={exercises ?? []}
                            fieldDetails={{
                              parentOne: name,
                              parentField: title,
                              arrayIndex: index,
                              fieldKey: "exercise_name",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <CustomTextField
                            formik={formik}
                            name={`${name}.${title}.${index}.duration`}
                            fieldDetails={{
                              parentOne: name,
                              parentField: title,
                              arrayIndex: index,
                              fieldKey: "duration",
                            }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <CustomTextField
                            formik={formik}
                            name={`${name}.${title}.${index}.sets`}
                            fieldDetails={{
                              parentOne: name,
                              parentField: title,
                              arrayIndex: index,
                              fieldKey: "sets",
                            }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <CustomTextField
                            formik={formik}
                            name={`${name}.${title}.${index}.reps`}
                            fieldDetails={{
                              parentOne: name,
                              parentField: title,
                              arrayIndex: index,
                              fieldKey: "reps",
                            }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <CustomTextField
                            formik={formik}
                            name={`${name}.${title}.${index}.load`}
                            fieldDetails={{
                              parentOne: name,
                              parentField: title,
                              arrayIndex: index,
                              fieldKey: "load",
                            }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <CustomTextField
                            formik={formik}
                            name={`${name}.${title}.${index}.rest`}
                            fieldDetails={{
                              parentOne: name,
                              parentField: title,
                              arrayIndex: index,
                              fieldKey: "rest",
                            }}
                            fullWidth
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              mt={3}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => arrayHelpers.push(initialExerciseRow)}
                sx={{ width: "fit-content" }}
              >
                {i18n.exercises.add}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (formik.values?.[name]?.[title].length > 1)
                    arrayHelpers.remove(
                      formik.values?.[name]?.[title].length - 1
                    );
                }}
                sx={{ width: "fit-content" }}
              >
                {i18n.exercises.remove}
              </Button>
            </Box>
          </Box>
        </>
      )}
    />
  );
};

export default ExerciseForm;
