import React, { useContext, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { MainContext } from "../../context/MainContext";

import { Role, rpeValues, sessionObject } from "../../utils/enums";
import CustomTextLine from "../CustomTextLine";
import CustomTwoButtons from "../CustomTwoButtons";
import TrainingForm from "../../../pages/forms/TrainingForm";
import { useAppSelector } from "../../hooks";
import { selectPlan } from "../../reducers/plansSlice";
import { Box, Divider, Typography } from "@mui/material";
import DefaultPalette from "../../theme/palette";
import NewRow from "../NewRow";
import CustomBlueButton from "../CustomBlueButton";
import TrainingReportForm from "../../../pages/forms/TrainingReportForm";
import { utcToZonedTime, format } from "date-fns-tz";
import ExercisesListDisplay from "./ExerciseListDisplay";

interface Props {
  data: any;
  isPast: boolean;
  handleDeleteTraining: any;
  closeModal: any;
  setReload: any;
}

const WeeklyTrainingCard = (props: Props): React.ReactElement => {
  const { data, isPast, handleDeleteTraining, closeModal, setReload } = props;
  const palette = DefaultPalette("dark", "custom");

  const { i18n } = useContext(LanguageContext);
  const { currentProfile } = useContext(MainContext);
  const annualPlans = useAppSelector(selectPlan);

  const [editTraining, setEditTraining] = useState(false);
  const [deleteTraining, setDeleteTraining] = useState(false);
  const [addReport, setAddReport] = useState(false);

  const cond =
    data &&
    data?.report?.filter((el: any) => el.profile === currentProfile.id)?.length;

  const isReports = data && data?.report && data.report?.length > 0;

  const dateTime =
    data &&
    data?.day_time &&
    format(utcToZonedTime(data?.day_time, "Etc/UTC"), "dd. MM. yyyy. - HH:mm", {
      timeZone: "Etc/UTC",
    });


    function getRPEName(value: any) {
      const rpe = rpeValues.find(activity => activity.value === value.toString());
      return rpe ? rpe.name : "";
  }

  const futureTrainingData = [
    {
      text: i18n.plans.trainingData.sessionFocus,
      textValue: sessionObject[data?.session_type],
    },
    {
      text: i18n.plans.trainingData.sessionDescription,
      textValue: data?.session_description,
    },
    {
      text: i18n.plans.trainingData.dayTime,
      textValue: dateTime,
    },
    {
      text: i18n.plans.trainingData.durationInMinutes,
      textValue: data?.duration_in_minutes,
    },
    ...(currentProfile?.role !== Role.Athlete ? [{
      text: i18n.plans.trainingData.rpe,
      textValue: data?.rpe,
    }] : []),
  ];

  const pastTrainingData = [
    {
      text: i18n.plans.trainingData.sessionDescription,
      textValue: data?.session_description,
    },
    {
      text: i18n.plans.trainingData.sessionFocus,
      textValue: sessionObject[data?.session_type],
    },
    {
      text: i18n.plans.trainingData.durationInMinutes,
      textValue: data?.duration_in_minutes,
    },
    ...(currentProfile?.role !== Role.Athlete ? [{
      text: i18n.plans.trainingData.rpe,
      textValue: data?.rpe,
    }] : [])
  ];

  const pastAthleteData = [
    {
      text: i18n.plans.trainingData.athlete,
      textValue: annualPlans.valueAthlete?.name,
    },
    // {
    //   text: i18n.plans.trainingData.dayTime,
    //   textValue: dateTime,
    // },
  ];

  const reports =
    data &&
    data?.report &&
    data.report?.length > 0 &&
    data?.report.map((el: any) => {
      return [
        {
          text: i18n.plans.from,
          value: el?.profile_name !== "" ? el?.profile_name : "-",
        },
        ...(el.profile_role === Role.Athlete ? [{
          text: i18n.plans.trainingData.duration,
          value: el?.duration_in_minutes,
        }]: []),

        {
          text: i18n.plans.trainingData.text,
          value: el?.text,
        },
        ...(el.profile_role === Role.Athlete ? [{
          text: i18n.plans.trainingData.rpe,
          value: el?.rpe,
        }]: []),
      ];
    });

  const displayLines =
    reports &&
    reports?.length > 0 &&
    reports.map((report: any, index: any) => {
      return (
        <React.Fragment key={index}>
          {report.map((el: any, innerIndex: any) => (
            <React.Fragment key={innerIndex}>
              <CustomTextLine text={el.text} textValue={el.value} />
              <NewRow height={10} />
            </React.Fragment>
          ))}
          <Divider sx={{ color: palette.custom.lightGrayWithOpacity }} />
        </React.Fragment>
      );
    });

  return (
    <div>
      <Typography
            sx={{
              textAlign: "center",
              color: palette.custom.white,
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
          {pastAthleteData.map((val: any, idx: any) => {
            if (val.textValue) {
              return (
                <CustomTextLine
                  key={idx}
                  text={val.text}
                  textValue={val.textValue}
                />
              );
            }
            return null;
          })}
      </Typography>
      <NewRow height={'20px'} />
      {!editTraining &&
        futureTrainingData &&
        futureTrainingData.map((val: any, idx: any) => {
          if (val.textValue) {
            return (
              <CustomTextLine
                key={idx}
                text={val.text}
                textValue={val.textValue}
              />
            );
          }
          return null;
        })}
      {data?.exercises?.length>0 && <ExercisesListDisplay data={data?.exercises} />}
      {!editTraining &&
        !deleteTraining &&
        // !isPast &&
        currentProfile.role !== Role.Athlete && (
          <CustomTwoButtons
            variant="editDelete"
            type="training"
            onClickOne={() => setEditTraining(true)}
            onClickTwo={() => setDeleteTraining(true)}
          />
        )}
      {editTraining && !deleteTraining && (
        <TrainingForm
          edit={true}
          training={data}
          annual={annualPlans.value}
          closeModal={() => {
            setEditTraining(false);
          }}
        />
      )}
      {deleteTraining && (
        <CustomTwoButtons
          variant="deleteEvent"
          type="training"
          onClickOne={() => handleDeleteTraining()}
          onClickTwo={() => setDeleteTraining(false)}
        />
      )}
      {isPast && (
        <Box>
          {/* <Divider sx={{ color: palette.custom.lightGray }} /> */}
          {/* <Typography
            sx={{
              textAlign: "center",
              color: palette.custom.white,
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {i18n.plans.trainingDetails}
          </Typography> */}

          {/* {pastTrainingData.map((val: any, idx: any) => {
            if (val.textValue) {
              return (
                <CustomTextLine
                  key={idx}
                  text={val.text}
                  textValue={val.textValue}
                />
              );
            }
            return null;
          })} */}
          <Divider sx={{ color: palette.custom.lightGray }} />

          <Typography
            sx={{
              textAlign: "center",
              color: palette.custom.white,
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {i18n.plans.reports}
          </Typography>

          {!isReports && (
            <Typography
              sx={{
                textAlign: "center",
                color: palette.custom.white,
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              {i18n.plans.noTrainingReport}
            </Typography>
          )}

          {isReports && displayLines}

          {cond === 0 && !addReport && (
            <CustomTwoButtons
              variant="addReport"
              onClickOne={() => setAddReport(true)}
              onClickTwo={closeModal}
            />
          )}

          {addReport && (
            <TrainingReportForm
              training={annualPlans.valueWeek}
              closeModal={() => setAddReport(false)}
              setReload={setReload}
            />
          )}

          {!addReport && isReports && cond !== 0 && (
            <React.Fragment>
              <NewRow height={"20px"} />
              <CustomBlueButton
                onPress={closeModal}
                label={i18n.plans.cancel}
                textColor={palette.custom.gray}
                backgroundColor={"transparent"}
                fontWeight={"normal"}
                textDecoration={"underline"}
              />
            </React.Fragment>
          )}
        </Box>
      )}
    </div>
  );
};
export default WeeklyTrainingCard;
