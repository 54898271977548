import React from "react";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Area,
  Bar,
  Line,
} from "recharts";
import { formatSubphaseDate, getAnnualStatsByWeek } from "../../resources/simpleFunction";
import DefaultPalette from "../../theme/palette";

interface Props {
  weekStats: any;
}

const SubphaseGraph = ({ weekStats }: Props) => {
  const palette = DefaultPalette("dark", "custom");

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const item = payload[0]?.payload;
      return (
        <div
          style={{
            backgroundColor: "#222",
            padding: "10px",
            borderRadius: "5px",
            color: "#fff",
          }}
        >
          <p style={{ fontWeight: "bold", color: "#fff" }}>
            {formatSubphaseDate(item.start_date, item.end_date)}
          </p>
          <p style={{ color: palette.custom.graph.grey }}>
            Strain: {item.stats.strain}
          </p>
          <p style={{ color: palette.custom.graph.blue }}>
            WTL: {item.stats.weekly_load}
          </p>
          <p style={{ color: palette.custom.graph.orange }}>
            Monotony: {item.stats.monotony}
          </p>
        </div>
      );
    }
    return null;
  };


  return (
    <ComposedChart
      data={weekStats}
      margin={{ top: 5, right: 0, bottom: 5, left: 30 }}
      width={1100}
      height={400}
    >
      <XAxis
        dataKey="start_date"
        tickFormatter={(value, index) => {
          const item = weekStats[index];
          return formatSubphaseDate(value, item?.end_date);
        }}
      />
      {/* Left Y-axis for strain and WTL */}
      <YAxis tickCount={6} domain={[0, "dataMax + 1000"]} yAxisId="big" />
      {/* Right Y-axis for monotony */}
      <YAxis
        yAxisId="small"
        orientation="right"
        tickCount={6}
        domain={[0, "dataMax"]}
      />
      <Tooltip content={<CustomTooltip />} />
      <Legend
        verticalAlign="top"
        align="left"
        iconSize={28}
        wrapperStyle={{
          marginLeft: 75,
          paddingBottom: 5,
          width: "fit-content",
        }}
      />
      <CartesianGrid stroke="#f5f5f523" strokeDasharray="0 0" />
      {/* Area for strain (left Y-axis) */}
      <Area
        yAxisId={"big"}
        type="linear"
        dataKey="stats.strain"
        name="Strain"
        fill={palette.custom.graph.grey}
        stroke={palette.custom.graph.grey}
        legendType="rect"
      />
      {/* Bar for WTL (left Y-axis) */}
      <Bar
        yAxisId={"big"}
        dataKey="stats.weekly_load"
        name="WTL"
        barSize={60}
        fill={palette.custom.graph.blue}
        radius={[5, 5, 0, 0]}
      />
      {/* Line for monotony (right Y-axis) */}
      <Line
        yAxisId={"small"} // Use the right Y-axis
        type="linear"
        dataKey="stats.monotony"
        name="Monotony"
        stroke={palette.custom.graph.orange}
        strokeWidth={2}
      />
    </ComposedChart>
  );
};

export default SubphaseGraph;
