import React, { useContext, useState } from "react";

import * as yup from "yup";

import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";
import {
  applyTrainingTemplate,
  deleteTrainingTemplate,
  selectPlan,
} from "../../app/reducers/plansSlice";

interface Props {
  closeModal?: any;
}

const ChooseDeleteTemplateForm = (props: Props): React.ReactElement => {
  const { closeModal } = props;
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const { i18n } = useContext(LanguageContext);
  const annualPlans = useAppSelector(selectPlan);

  const fields = [
    {
      name: "template",
      label: i18n.plans.template,
      type: "select",
      selectValues:
        annualPlans?.templates?.map((i: any) => {
          return { value: i.id, name: i.name };
        }) ?? [],
      placeholder: i18n.plans.chooseTemplate,
      edit: false,
    },
  ];

  const init = {
    template: "",
  };

  const scheme = yup.object({
    template: yup.string().required(i18n.plans.requiredField),
  });


  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);
      const formData = {
        ...values,
        annual_plan: annualPlans?.value?.id,
        start_of_week: annualPlans?.valuesWeek?.[0].day,
        end_of_week:
          annualPlans?.valuesWeek?.[annualPlans?.valuesWeek?.length - 1].day,
      };

      let res;

      res = await dispatch(applyTrainingTemplate(formData));

      if (res?.meta.requestStatus === "fulfilled") {
        closeModal();
      } else {
        setError(i18n.auth.errors.error);
      }

      setDisabled(false);
    } catch (error) {
      setDisabled(false);
    }
  };

  const onDeleteTemplate = async (values: any) => {
    try {
      setDisabled(true);

      if (values?.template) {
        let res;

        res = await dispatch(deleteTrainingTemplate(values?.template));

        if (res?.meta.requestStatus === "fulfilled") {
          closeModal();
        } else {
          setError(i18n.auth.errors.error);
        }
      } else {
        setError(i18n.auth.errors.chooseTemplate);
      }

      setDisabled(false);
    } catch (error) {
      setDisabled(false);
    }
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
          cancelFunction={closeModal}
          onDeleteFunction={onDeleteTemplate}
        />
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};
export default ChooseDeleteTemplateForm;
