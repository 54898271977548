import React, { useEffect, useRef, useState } from "react";
import { BarChart, Bar, ResponsiveContainer } from "recharts";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Area,
  Line,
} from "recharts";
import { useLanguageContext } from "../../context/LanguageContext";
import DefaultPalette from "../../theme/palette";
import { Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { findMaxDailyLoad, findMaxTrainingsCount, formatSubphaseDate } from "../../resources/simpleFunction";
import SubphaseGraph from "./SubphaseGraph";
import NewRow from "../NewRow";

interface Props {
  subphase?: Record<string, any>;
}

const SubphaseTable = (props: Props): React.ReactElement => {
  const { subphase } = props;

  const { i18n } = useLanguageContext();
  const palette = DefaultPalette("dark", "custom");

  const maxTraining = findMaxTrainingsCount(subphase)
  const maxLoad = findMaxDailyLoad(subphase)
  const days: string[] = ["M", "Tu", "W", "Th", "F", "Sa", "Su"];

  const firstPartHeader = [
    {
      text: i18n.subphase.mesocycle,
    },
    {
      text: i18n.subphase.microcycle,
    },
    {
      text: i18n.subphase.dates,
    },
    {
      text: i18n.subphase.high,
    },
    {
      text: i18n.subphase.medium,
    },
    {
      text: i18n.subphase.low,
    },
    {
      text: "",
    },
    {
      text: i18n.subphase.tl,
    }
  ];
  
  const trainingHeader = [
    {
      text: i18n.subphase.duration,
    },
    {
      text: i18n.subphase.rpe,
    },
  ];

    const graphHeader = [
      {
        text: i18n.subphase.microcycle,
      },
      {
        text: i18n.subphase.wtl,
      },
      {
        text: i18n.subphase.monotony,
      },
      {
        text: i18n.subphase.strain,
      },
    ];

  const styles: any = {
    border: {
      border: `0.3px solid ${palette.custom.lightGray}`
    },
    headerCell: {
      border: `1px solid ${palette.custom.background}`,
      backgroundColor: palette.custom.lightGray,
      padding: "5px",
      minWidth: "106px",
      position: "relative",
    },
    text: {
      color: palette.custom.white,
      fontSize: "0.7rem",
      fontWeight: "normal",
      textAlign: "center",
      padding: "0px",
    },
    headerText: {
      color: palette.custom.white,
      fontWeight: "bold",
    },
    cellStyle: {
      height: "40px",
      // width: `${cellWidth}px`,
      minWidth: "50px",
      border: `0.3px solid ${palette.custom.lightGray}`,
    },
    graphCell: {
      height: "120px",
      // width: `${cellWidth}px`,
      // minWidth: `${200 / 7}px`,
      borderTop: `0.3px solid ${palette.custom.lightGray}`,
      borderBottom: `0.3px solid ${palette.custom.lightGray}`,
    },
    smallCellStyle: {
      height: "40px",
      // width: `${cellWidth}px`,
      // minWidth: `${200 / 7}px`,
      border: `0.3px solid ${palette.custom.lightGray}`,
    },
    noPhases: {
      color: palette.custom.white,
      fontWeight: "bold",
      textAlign: "center",
      padding: "10px",
      fontSize: "1rem",
    },
    table: {
      borderCollapse: "collapse",
      padding: "0px",
      width: "100%",
    },
    mainBox: {
      display: "flex",
      flexDirection: "row",
    },
    center: {
      display: "flex",
      justifyContent: "center",
    },
    insideBox: {
      width: "100%",
    },
  };


  const renderRow = (weekly_statistics: any, key: "index" | "date" | "wtl" | "monotony" | "strain") => {
    return (
      <>
        {weekly_statistics?.map((item: any, index: number) => {
          let value;
          switch (key) {
            case "index":
              value = index + 1;
              break;
            case "date":
              value = formatSubphaseDate(item?.start_date, item?.end_date);
              break;
            case "monotony":
              value = item?.stats?.monotony;
              break;
            case "strain":
              value = item?.stats?.strain;
              break;
            case "wtl":
              value = item?.stats?.weekly_load;
              break;
            default: 
              value =  "";
          }

          return (
            <td key={`${key}-${index}`} colSpan={7} style={styles.cellStyle}>
              <Typography noWrap sx={styles.text}>
                {value}
              </Typography>
            </td>
          );
        })}
      </>
    );
  }

const renderBarCell = (dailyStatistics: any[], maxLoad: number, numberOfTd: number) => {
  const numberOfCell = 7 * numberOfTd;
  return (
    <>
      {dailyStatistics?.map((day: any, index: number) => {
        if (day.daily_load === 0) {
          return (
            <td
              key={`graphbar-${index}`}
              colSpan={1}
              style={{
                height: "120px",
                minWidth: `max(calc(88vw / ${numberOfCell}), 50px)`,
              }}
            />
          );
        }

        const barHeight = (day.daily_load / maxLoad) * 110;
        return (
          <td
            key={`graphbar-${index}`}
            colSpan={1}
            style={{
              height: "120px",
              textAlign: "center",
              verticalAlign: "bottom",
              justifyContent: "center",
              minWidth: `max(calc(88vw / ${numberOfCell}), 50px)`,
            }}
          >
            <div
              style={{
                display: "inline-block",
                width: "80%",
                height: `${barHeight}px`,
                backgroundColor: palette.custom.graph.blue,
                transition: "height 0.3s ease",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                visibility: "visible",
              }}
            />
          </td>
        );
      })}
    </>
  );
};


const renderLoadCell = (weeklyStatistics: any[]) => {
  return (
    <>
      {weeklyStatistics.flatMap((week: any, weekIndex: number) =>
        Object.values(week.stats.days).map((day: any, dayIndex: number) => {
          const dailyLoad = day?.daily_load || 0;
          return (
            <td key={`load-${weekIndex}-${dayIndex}`} style={{...styles.cellStyle, borderWidth: "4px"}}>
              <Typography noWrap sx={{...styles.text, fontWeight: "bold"}}>
                {dailyLoad}
              </Typography>
            </td>
          );
        })
      )}
    </>
  );
};


const renderDurationRpe = (weeklyStatistics: any[], maxTraining: number) => {
  return (
    <>
      {/* Generate maxTraining number of rows */}
      {Array.from({ length: maxTraining }).flatMap((_, trainingIndex) => [
        // Row for Duration
        <tr key={`duration-row-${trainingIndex}`}>
          {weeklyStatistics.flatMap((week: any, weekIndex: number) =>
            Object.values(week.stats.days).map((day: any, dayIndex: number) => {
              const training = day.trainings?.[trainingIndex]; // Get training at index
              return (
                <td
                  key={`duration-${weekIndex}-${dayIndex}-${trainingIndex}`}
                  style={{ ...styles.cellStyle, textAlign: "center" }}
                >
                  <Typography sx={styles.text}>
                    {training ? training.duration : 0}
                  </Typography>
                </td>
              );
            })
          )}
        </tr>,

        // Row for RPE
        <tr key={`rpe-row-${trainingIndex}`}>
          {weeklyStatistics.flatMap((week: any, weekIndex: number) =>
            Object.values(week.stats.days).map((day: any, dayIndex: number) => {
              const training = day.trainings?.[trainingIndex]; // Get training at index
              return (
                <td
                  key={`rpe-${weekIndex}-${dayIndex}-${trainingIndex}`}
                  style={{ ...styles.cellStyle, textAlign: "center" }}
                >
                  <Typography sx={styles.text}>
                    {training ? training.rpe : 0}
                  </Typography>
                </td>
              );
            })
          )}
        </tr>,
      ])}
    </>
  );
};


  return (
    <>
      <div style={styles.mainBox}>
        <table style={{ width: "10vw" }}>
          <tbody>
            {firstPartHeader.map((header: any, index: number) => (
              <tr key={`firstHeader-${index}`} style={{ height: "40px" }}>
                <th style={styles.headerCell}>
                  <Typography style={styles.headerText}>
                    {header.text}
                  </Typography>
                </th>
              </tr>
            ))}
            {Array.from({ length: maxTraining }).map((_) =>
              trainingHeader.map((header, headerIndex) => (
                <tr
                  key={`trainingHeader-${headerIndex}`}
                  style={{ height: "40px" }}
                >
                  <th style={styles.headerCell}>
                    <Typography style={styles.headerText}>
                      {header.text}
                    </Typography>
                  </th>
                </tr>
              ))
            )}
            {graphHeader.map((header: any, graphIndex: number) => (
              <tr key={`graphHeader-${graphIndex}`} style={{ height: "40px" }}>
                <th style={styles.headerCell}>
                  <Typography style={styles.headerText}>
                    {header.text}
                  </Typography>
                </th>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="custom-scrollbar" style={{ overflowX: "auto" }}>
          <table style={{ minWidth: "90vw" }}>
            <tbody>
              <tr>
                <td
                  colSpan={subphase?.weekly_statistics?.length * 7}
                  style={styles.cellStyle}
                >
                  <Typography noWrap sx={styles.text}>
                    {subphase?.subphase_name}
                  </Typography>
                </td>
              </tr>
              <tr>{renderRow(subphase?.weekly_statistics, "index")}</tr>
              <tr>{renderRow(subphase?.weekly_statistics, "date")}</tr>
              <tr>
                {subphase?.weekly_statistics.map(
                  (week: any, weekIndex: number) => {
                    const dailyStats = Object.values(week.stats.days);
                    return (
                      <td
                        key={`graph-${weekIndex}`}
                        colSpan={7}
                        style={styles.border}
                      >
                        {renderBarCell(
                          dailyStats,
                          maxLoad,
                          subphase?.weekly_statistics?.length ?? 1
                        )}
                      </td>
                    );
                  }
                )}
              </tr>
              <tr>
                {subphase?.weekly_statistics.flatMap(
                  (week: any, weekIndex: number) =>
                    days.map((day, dayIndex) => {
                      const hasEvent = week?.stats?.days[dayIndex]?.has_competition === true;
                      return (
                      <td
                        key={`week-${weekIndex}-day-${dayIndex}`}
                        colSpan={1}
                        style={{ ...styles.cellStyle, backgroundColor: hasEvent ? palette.custom.orange : palette.custom.darkBlue }}
                      >
                        <Typography noWrap sx={styles.text}>
                          {day}
                        </Typography>
                      </td>
                    )})
                )}
              </tr>
              <tr>{renderLoadCell(subphase?.weekly_statistics)}</tr>
              {renderDurationRpe(subphase?.weekly_statistics, maxTraining)}
              <tr>{renderRow(subphase?.weekly_statistics, "index")}</tr>
              <tr>{renderRow(subphase?.weekly_statistics, "wtl")}</tr>
              <tr>{renderRow(subphase?.weekly_statistics, "monotony")}</tr>
              <tr>{renderRow(subphase?.weekly_statistics, "strain")}</tr>
            </tbody>
          </table>
        </div>
      </div>
      <NewRow height={30} />
      <div style={styles.center}>
        <SubphaseGraph weekStats={subphase?.weekly_statistics} />
      </div>
      <NewRow height={30} />
    </>
  );
};

export default SubphaseTable;
