import React, { useContext, useState } from "react";

import * as yup from "yup";

import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";
import {
  postCompetition,
  putCompetition,
  selectPlan,
} from "../../app/reducers/plansSlice";
import { importanceValue } from "../../app/utils/enums";
import { format } from "date-fns";

interface Props {
  edit?: boolean;
  annual?: any;
  competition?: any;
  closeModal: any;
}

const CompetitionForm = (props: Props): React.ReactElement => {
  const { edit, annual, competition, closeModal } = props;
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const { i18n } = useContext(LanguageContext);
  const annualPlans = useAppSelector(selectPlan);

  const fields = [
    ...(!edit
      ? [
          {
            name: "start_date",
            label: i18n.plans.startDate,
            type: "date",
          },
          {
            name: "end_date",
            label: i18n.plans.endDate,
            type: "date",
          },
        ]
      : [
          {
            name: "date_of_competition",
            label: i18n.plans.dateComp,
            type: "date",
          },
        ]),

    {
      text: i18n.plans.eventDetails,
      type: "title",
    },
    {
      name: "name",
      label: i18n.plans.nameComp,
      placeholder: i18n.plans.placName,
      type: "text",
    },
    {
      name: "location",
      label: i18n.plans.location,
      placeholder: i18n.plans.placLocation,
      type: "text",
    },
    {
      name: "importance",
      label: i18n.plans.importance,
      placeholder: i18n.plans.placImportance,
      type: "select",
      selectValues: importanceValue,
    },
    {
      name: "target",
      label: i18n.plans.target,
      placeholder: i18n.plans.placTarget,
      type: "text",
    },
  ];

  const init = {
    name: competition?.name ?? "",
    ...(!edit
      ? {
          start_date: competition?.start_date
            ? new Date(competition?.start_date)
            : new Date(annualPlans?.valuesWeek[0]?.day).getTime() >
              new Date().getTime()
            ? new Date(annualPlans?.valuesWeek[0]?.day)
            : new Date(),
          end_date: competition?.end_date
            ? new Date(competition?.end_date)
            : new Date(annualPlans?.valuesWeek[6]?.day).getTime() >
              new Date().getTime()
            ? new Date(annualPlans?.valuesWeek[6]?.day)
            : new Date(),
        }
      : {
          date_of_competition: competition?.date_of_competition
            ? new Date(competition?.date_of_competition)
            : new Date(annualPlans?.valuesWeek[0]?.day).getTime() >
              new Date().getTime()
            ? new Date(annualPlans?.valuesWeek[0]?.day)
            : new Date(),
        }),

    location: competition?.location || "",
    target: competition?.target || "",
    importance: competition?.importance || "",
  };

  const scheme = yup.object({
    start_date: yup.date().when("edit", {
      is: false,
      then: (schema) => schema.required(i18n.plans.requiredField),
      otherwise: (schema) => schema.notRequired(),
    }),
    end_date: yup.date().when("edit", {
      is: false,
      then: (schema) =>
        schema
          .required(i18n.plans.requiredField)
          .min(yup.ref("start_date"), "End date can't be before start date"),
      otherwise: (schema) => schema.notRequired(),
    }),
    date_of_competition: yup.date().when("edit", {
      is: true,
      then: (schema) => schema.required("Date of competition is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    name: yup.string().required(i18n.plans.requiredField),
    target: yup.string().required(i18n.plans.requiredField),
    location: yup.string().required(i18n.plans.requiredField),
  });

  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);

      const formData = {
        ...values,
        ...(!edit ? {
          start_date: format(new Date(values?.start_date ?? ""), "yyyy-MM-dd"),
        end_date: format(new Date(values?.end_date ?? ""), "yyyy-MM-dd")} : {}),
        annual_plan: annual.id,
      };

      const editData = {
        ...values,
        ...(edit ? {date_of_competition: format(
          new Date(values?.date_of_competition ?? ""),
          "yyyy-MM-dd"
        )} : {}),
        annual_plan: annual.id,
        id: competition?.id,
      };
      
      let res;

      if (!edit) res = await dispatch(postCompetition(formData));

      if (edit) res = await dispatch(putCompetition(editData));

      if (res?.meta.requestStatus === "fulfilled") {
        closeModal();
        // navigate('/')
        // navigate(0)
      } else {
        setError("Error!");
      }

      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
          cancelFunction={closeModal}
          context={edit}
        />
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};
export default CompetitionForm;
