import React from "react";

import MenuItem from "@mui/material/MenuItem";
import DefaultPalette from "../theme/palette";
import { Box, IconButton, Select, Typography } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useAppDispatch } from "../hooks";
import { deleteExerciseTrainingTemplate, getExerciseTrainingTemplate } from "../reducers/plansSlice";
import { unmapExercisesWithOptions } from "../resources/simpleFunction";
import { initialExercises } from "../../pages/forms/TrainingForm";
import ConfirmModal from "./ConfirmModal";

interface Props {
  name: string;
  formik: any;
  label: string;
  selectValues: any;
  disabled?: boolean;
  placeholder?: string;
  exercisesOptions?: any;
}

const CustomDropdownDeleteField = (props: Props): React.ReactElement => {
  const palette = DefaultPalette("dark", "custom");
  const dispatch = useAppDispatch()
  const [deleteModal, setDeleteModal] = React.useState(false);

  const onChangeTemplate = async(event: any) => {
    const idTemplate = event.target.value;
    props.formik.setFieldValue(props.name, idTemplate);
    const res = await dispatch(getExerciseTrainingTemplate(idTemplate));
    if (res.payload) {
      props.formik.setFieldValue(
        "exercises",
        unmapExercisesWithOptions(
          res.payload.template_exercises,
          props.exercisesOptions
        )
      );
    }
  };
  const onDeleteTepmplate = async() => {
    const idTemplate = props.formik.values[props.name];
    await dispatch(deleteExerciseTrainingTemplate(idTemplate));
    props.formik.setFieldValue(props.name, "");
    props.formik.setFieldValue(
      "exercises",
     initialExercises
    );
    setDeleteModal(false);

  };
  return (
    <Box sx={{ display: "flex" }}>
      <Select
        sx={{
          color: palette.custom.lightGray,
          width: "100%",
          backgroundColor: palette.custom.background,
          input: { color: palette.custom.lightGray },
          outline: { color: palette.custom.background },
          borderColor: palette.custom.background,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.custom.lightGray,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.custom.lightGray,
          },
        }}
        id={props.name}
        placeholder={props.placeholder}
        name={props.name}
        variant="outlined"
        value={props.formik?.values[props.name]}
        onChange={onChangeTemplate}
        error={
          props.formik.touched[props.name] &&
          Boolean(props.formik.errors[props.name])
        }
        displayEmpty
        renderValue={
          props.formik?.values[props.name] !== ""
            ? undefined
            : () => (
                <Typography
                  sx={{ color: palette.custom.lightGray, opacity: 0.5 }}
                >
                  {props.placeholder}
                </Typography>
              )
        }
      >
        {props.selectValues.map((item: any, index: any) => (
          <MenuItem
            key={index}
            value={item.value}
            sx={{ color: palette.custom.lightGray }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {props.formik?.values[props.name] !== "" && <IconButton onClick={() => setDeleteModal(true)}>
        <Delete />
      </IconButton>}
      {deleteModal && (
        <ConfirmModal
          open={deleteModal}
          text={"Are you sure you want delete this exercise template?"}
          isInModal={true}
          onClose={() => setDeleteModal(false)}
          onSubmit={onDeleteTepmplate}
        />
      )}
    </Box>
  );
};

export default CustomDropdownDeleteField;
